import {AppNames, AppType} from "@/utils/config";
import router from "@/router";
import readXlsxFile from 'read-excel-file'

const enc = require("@/data/enc.json")


export function clearJSONValues(obj) {
    obj.map(v=>{
        v = Object.assign({}, v);
        for( let k in v ) v[k] = "";
        return v;
    });
}

export function getUUID(email) {
    const getUUID = require('uuid-by-string');
    let uuidHash = getUUID(email + Date.now().toString());

    return uuidHash;
}

export function getS63CKTInfo(cellName) {
    let price = 0;
    let isSpecial = false;
    let period = [3, 4, 5, 6, 7, 8, 9, 12];
    let isNotForSale = false;

    let country = cellName.substring(0, 2);

    switch (country) {
        case 'AU': price = 20.56; break;
        case 'AL': price = 23.24; break;
        case 'AR': price = 51.41; break;
        case 'BD': price = 51.41; break;
        case 'ВН': price = 25.78; break;
        case 'ВЕ': price = 25.18; break;
        case 'BR': price = 26.52; break;
        case 'GB': price = 32.63; break;
        case 'UK': price = 32.63; break;
        case 'VE': price = 40.23; break;
        case 'ЕА': price = 5.96; break;
        case 'VN': price = 35.76; break;
        case 'DE': price = 17.14; break;
        // case 'CN': price = 566.20; isSpecial = true; period = [3, 6, 9, 12]; break;
        // case 'C2': price = 566.20; isSpecial = true; period = [3, 6, 9, 12]; break;
        // case 'C3': price = 566.20; isSpecial = true; period = [3, 6, 9, 12]; break;
        case 'GR': price = 25.78; break;
        case 'GE': price = 26.82; break;
        case 'DK': price = 63.47; break;
        case 'EG': price = 86.42; break;
        case 'IL': price = 47.98; break;
        case 'IN': price = 25.78; period = [12]; break;
        case 'ID': price = 37.25; period = [3, 6, 9, 12]; break;
        case 'IR': price = 37.25; break;
        case 'IS': price = 32.18; break;
        case 'ES': price = 20.56; break;
        case 'IT': price = 25.78; break;
        // case 'СА': price = 30.84; isSpecial = true; break;
        // case 'CN': price = 17.88; period = [3, 6, 9, 12]; break;
        case 'СО': price = 34.27; break;
        case 'CU': price = 30.84; break;
        case 'LV': price = 25.03; break;
        case 'LT': price = 14.30; break;
        case 'МY': price = 47.98; break;
        // case 'MSS': price = 52.15; break;
        case 'МТ': price = 37.70; break;
        case 'MX': price = 36.80; break;
        case 'NG': price = 42.91; break;
        case 'NL': price = 14.45; break;
        case 'NU': price = 20.56; break;
        case 'NZ': price = 20.56; break;
        case 'NO': price = 14.01; break;
        case 'ОМ': price = 51.41; break;
        case 'РК': price = 9.39; break;
        case 'РА-СА': price = 51.41; break;
        case 'РА-МА': price = 42.91; break;
        case 'PG': price = 20.56; break;
        case 'РЕ': price = 14.60; break;
        case 'PL': price = 21.46; break;
        case 'РТ': price = 30.84; break;
        // case 'RU': price = 26.82; isSpecial = true; break;
        // case 'RU': price = 20.56; isSpecial = true; break;
        case 'RO': price = 36.80; break;
        // case 'SG': price = 74.50; isSpecial = true; period = [12]; break;
        case 'SI': price = 30.84; break;
        case 'SB': price = 20.56; break;
        case 'SR': price = 17.14; break;
        case 'US': price = 1.79; break;
        case '1U': price = 17.88; break;
        case 'ТО': price = 20.56; break;
        case 'TN': price = 32.63; break;
        case 'TR': price = 18.92; break;
        case 'UA': price = 25.03; break;
        case 'UY': price = 56.62; break;
        case 'РН': price = 42.91; break;
        case 'FI': price = 18.18; break;
        case 'FR': price = 33.08; break;
        case 'HR': price = 22.50; break;
        case 'МЕ': price = 23.24; break;
        case 'CL': price = 25.78; break;
        case 'SE': price = 15.79; break;
        case 'ЕС': price = 25.78; break;
        case 'EE': price = 25.18; break;
        case 'ZA': price = 24.88; break;
        case 'KR': price = 10.43; period = [3, 6, 9, 12]; break;
        // case 'ЈР': price = 11.92; isSpecial = true; break;
        default: isNotForSale = true;
    }

    return {price: price, special: isSpecial, notforsale: isNotForSale, period: period};
}


export function findObject(arr, key, value) {
    let obj = arr.find(o => o[key] === value);

    return obj;
}

export function findAndReplaceObject(arr, key, value, newValue) {
    let obj = arr.find((o, i) => {
        if (o[key] === value) {
            // arr[i] = {key: 'new string', value: 'this', other: 'that'};
            return true;
        }
    });
}
export function filterByAppName(arr, name) {

    console.log(name);

    if (!arr) {
        return null;
    }

    if (name === 'all') {
        return arr;
    }

    // TODO
    if (name === 'unknown') {
        let instsWithoutAppState  = arr.filter(function (e) {
            return e["appState"] == null || e["appState"].programInfo.name === "";
        });

        return instsWithoutAppState;
    }

    let instsWithAppState  = arr.filter(function (e) {
        return e["appState"] != null;
    });

    let filter = "";
    if (name === "np") {
        filter = "Njord Pilot"
    } else if (name === "npm") {
        filter = "NPMobile"
    } else if (name === "mkart") {
        filter = "mKart"
    } else if (name === "nv") {
        filter = "Vega"
    } else if (name === "m-Connect") {
        filter = "m-Connect"
    } else if (name === "ECDIS") {
        filter = "ECDIS"
    }

    let insts  = instsWithAppState.filter(function (e) {
        return e["appState"].programInfo.name === filter;
    });

    return insts;
}

export function getTypeByStatus(status) {
    let type = 'unknown'

    switch (status) {
        case 'request':
            type = "pre_order"
            break
        case 'to pay':
            type = "owner_approved"
            break
        case 'cancelled':
            type = "cancelled_by_distirbutor"
            break
    }

    return type
}

export function getStatusByType(type) {
    let status = 'unknown'

    switch (type) {
        case 'pre_order':
            status = "request"
            break
        case 'owner_approved':
            status = "to pay"
            break
        case 'new':
            status = "to pay"
            break
        case 'paid':
        case 'ready_to_publish':
        case 'awaiting_charts"':
        case 'adding_charts"':
            status = "processing"
            break
        case 'complete':
            status = "complete"
            break
        case 'cancelled_by_distirbutor':
            status = "cancelled"
            break
        case 'error':
            status = "error"
            break
    }

    return status
}

export function getAppType(appName) {
    let appType = -1;

    // get appType
    if (appName === 'ECDIS')
        appType = 11;
    else if (appName === 'm-Connect')
        appType = 10;
    else if (appName === 'Vega')
        appType = 2;
    else if (appName === 'Njord Pilot')
        appType = 1;
    else if (appName === 'mKart')
        appType = 0;

    return appType;
}

export function getAppName(appType) {
    let appName = '';

    // get appType
    if (appType === 11)
        appName = 'ECDIS';
    else if (appType === 10)
        appName = 'm-Connect';
    else if (appType === 2)
        appName = 'Vega';
    else if (appType === 1)
        appName = 'Njord Pilot';
    else if (appType === 0)
        appName = 'mKart';
    else
        appName = 'UNKNOWN';

    return appName;
}

export function productsByDeviceId(arr, deviceID) {
    let insts  = arr.filter(function (e) {
        return e["deviceId"] === deviceID;
    });

    return insts;
}

export function productsByEmail(arr, email) {
    let insts  = arr.filter(function (e) {
        return e["email"] === email;
    });

    return insts;
}


export function roundNumber(value) {
    return Number((value).toFixed(1));
}

export function trimCoordinates(coordinates) {
    return coordinates?.toString().substring(0, 9) || '';
}

export function truncStrng(str, n) {
    if (str.length > n) {
        return str.substring(0, n) + '...';
    }
    return str;
}

export function daysDiff(dateUTC1, dateUTC2) {
    let currentDate = new Date(dateUTC1);
    let prevDate = new Date(dateUTC2);

    const diffTime = Math.abs(currentDate - prevDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function nextDate(dateUTC1, months) {
    let nextDate = new Date(dateUTC1)
    nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + months));
    nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth()+1, 0);

    return nextDate;
}


export function daysToMonth(dateUTC1, months) {
    let firstDate = new Date();
    let nextDate = new Date(dateUTC1)
    nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + months));

    const diffTime = nextDate - firstDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // console.log("dima", firstDate, nextDate, diffTime, diffDays)

    return diffDays;
}

export function daysOld(dateUTC) {
    let currentDate = new Date();
    let prevDate = new Date(dateUTC);

    const diffTime = Math.abs(currentDate - prevDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}
export function daysTo(dateUTC) {
    let currentDate = new Date();
    let prevDate = new Date(dateUTC);

    const diffTime = Math.abs(currentDate - prevDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function daysOldWithSign(dateUTC) {
    let currentDate = new Date();
    let prevDate = new Date(dateUTC);

    const diffTime = prevDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function formatDate(value) {
    let date = new Date(value);
    if (isNaN(date)) {
        return '-';
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    let formattedDate = year + '-' + month + '-' + day;
    let formattedTime = hours + ":" + minutes;
    let formattedDateTime = formattedDate + " " + formattedTime;
    let isValid = true;

    if (year < 2000) {
        isValid = false;
    }

    return {date: formattedDate, time: formattedTime, datetime: formattedDateTime, isvalid: isValid};
}























export function filterAllByType(arr, key, value) {
    let items = arr;

    if (!items) { return; }

    items = arr.filter(function (e) {

        if (value === "mkart") {
            return e[key] === 0;
        } else  {
            return e[key] === 1;
        }
    });

    return items;
}











export function currentRouterId() {
    return router.currentRoute.meta.id;
}


export function addIndexToArray(arr) {
    let index = 1;
    arr.forEach(element => element.aindex = index++);

    return arr;
}


export function preFilter(arr, appType) {
    let filteredArray = arr;

    if (appType === AppType.MKART) {
        filteredArray = filteredArray.filter(function (e) {
            return e.platformName === 'Android' || e.platformName === 'iOS';
        });
    }
    return filteredArray;
}

export function getENCInfo(id) {
    let info = enc.find(el => el.id === id);
    return info
}

export function getBandName(code) {
    let chartType;
    switch (code){
        case 1:
            chartType = 'overview';
            break
        case 2:
            chartType = 'general';
            break
        case 3:
            chartType = 'coastal';
            break
        case 4:
            chartType = 'approach';
            break
        case 5:
            chartType = 'harbour';
            break
        case 6:
            chartType = 'berthing';
            break
    }
    return chartType;
}










export function dumpFile(name, content) {
    // let a = document.createElement("a");
    // let jsonString = [JSON.stringify(content, null, 2)];
    // let file = new Blob([jsonString], {type: 'text/plain'});
    // a.href = URL.createObjectURL(file);
    // a.download = "_dump_" + name + ".txt";
    // a.click();
}

export function getStyleBoolean(value) {
    let style = value ? 'smt11' :  "smt14";
    return style;
}

export function CSVParse(data) {
    const re = /(,|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^,\r\n]*))/gi
    const result = [[]]
    let matches
    while ((matches = re.exec(data))) {
        if (matches[1].length && matches[1] !== ',') result.push([])
        result[result.length - 1].push(
            matches[2] !== undefined ? matches[2].replace(/""/g, '"') : matches[3]
        )
    }
    return result.slice(1)
}









export function excelParser() {
    console.log("aaaaaa")

    fetch('excel.xlsx')
        .then(response => response.blob())
        .then(blob => readXlsxFile(blob))
        .then((rows) => {
            console.log(rows)
            // `rows` is an array of rows
            // each row being an array of cells.
        })

        // readXlsxFile('excel.xlsx').then((rows) => {
        //     console.log(rows)
        //     // `rows` is an array of rows
        //     // each row being an array of cells.
        // })
}

export function ExcelToJSON() {

    // let file = 'excel.xlsx';
    //
    // this.parseExcel = function(file) {
    //     var reader = new FileReader();
    //
    //     reader.onload = function(e) {
    //         var data = e.target.result;
    //         var workbook = XLSX.read(data, {
    //             type: 'binary'
    //         });
    //
    //         workbook.SheetNames.forEach(function(sheetName) {
    //             // Here is your object
    //             var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    //             var json_object = JSON.stringify(XL_row_object);
    //             console.log(json_object);
    //
    //         })
    //
    //     };
    //
    //     reader.onerror = function(ex) {
    //         console.log(ex);
    //     };
    //
    //     reader.readAsBinaryString(file);
    //  }
}