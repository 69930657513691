<template>
  <div>
      <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  props: ["map", "height"],
  data() {
    return {
      is_route_error: false,
      is_show_map_info: false,
      route_error_text: '',
      map_info_text: ''
    }
  },

  mounted() {
    this.map.init(this.$refs.mm);
  },
  beforeDestroy() {
  },
  methods: {
  }
}
</script>
<style scoped>
#mymap {
  z-index: 1;
}

/deep/ .my-class {
  background: black;
  color: white;
}

/deep/ .myclass > div {
  position: absolute !important;
  top: -10px !important;
  left: 10px !important;
  background-color: #77ccdd !important;
}

/deep/ .myclass > .modal-dialog > .modal-content {
  background-color: #77ccdd !important;
}

.leaflet-grab {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab {
  cursor: move;
}

</style>
