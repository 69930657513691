<template>
  <div>

    <b-modal id="modal-add-object" :title="this.addingObject?.name ?? ''" v-model="addObject" hide-footer>
      <div v-if="this.addingAttributes.length > 0">
        <b-form-select v-model="selected" :options="this.addingAttributes" size="sm"></b-form-select>
      </div>

      <b-button-group class="mt-3">
        <b-button size="sm" @click="$bvModal.hide('modal-add-object')">Cancel</b-button>
        <b-button size="sm" variant="success" @click="$bvModal.hide('modal-add-object');addDone()">Ok</b-button>
      </b-button-group>
    </b-modal>

    <b-modal id="modal-edit-object" :title="this.editableObject?.name ?? ''" v-model="editObject" hide-footer>
      <div v-if="this.editableObject && this.editableObject.availableValues && this.editableObject.type === 'enumeration'">
        <b-form-select v-model="selected" :options="options(this.editableObject)" size="sm"></b-form-select>
      </div>
      <div v-if="this.editableObject && this.editableObject.type === 'boolean'">
        <b-form-select v-model="selected" :options="bool_options()" size="sm"></b-form-select>
      </div>
      <div v-if="this.editableObject && (this.editableObject.type === 'integer' || this.editableObject.type === 'real' || this.editableObject.type === 'text'  || this.editableObject.type === 'coordinates')">
        <b-form-input v-model="selected" size="sm"></b-form-input>
      </div>

      <b-button-group class="mt-3">
        <b-button size="sm" @click="$bvModal.hide('modal-edit-object')">Cancel</b-button>
        <b-button v-if="this.editableObject && this.editableObject.type !== 'coordinates'" size="sm" variant="danger" @click="$bvModal.hide('modal-edit-object');deleteDone()">Delete</b-button>
        <b-button size="sm" variant="success" @click="$bvModal.hide('modal-edit-object');editDone()">Ok</b-button>
      </b-button-group>
    </b-modal>

    <div>
      <b-card class="mt-2" sub-title="Message" v-if="this.$parent.$parent.$parent.currentMessage !== ''">
      <span class="small-black-card">
      {{ this.$parent.$parent.$parent.currentMessage }}
        </span>
      </b-card>


      <div v-if="this.$parent.$parent.$parent.showCommand && !this.$parent.$parent.$parent.showLog && !isChangedItem()">
        <b-card class="mt-2" sub-title="Selected token">
        <span class="small-black-card">
          <span class="small-green">{{ getDescription() }}</span> in cell <span class="small-green">{{ selectedTokenCell }}</span>.

          <!--          {{selectedTokenID}}-->
          <!--          {{getDescription()}}-->
          <!--          <div v-html="this.$parent.$parent.$parent.selectedInfo"/>-->
          <!--          {{ this.$parent.$parent.$parent.selectedInfo }}-->
          <!--          Cell <span class="small-green">GB50182A</span>. <span class="small-green">Lighted Cardinal Buoy; spar (spindle)</span>. Name: <span class="small-green">N. Shoal</span>, coordinates: <span class="small-green">49.88507, -6.41979</span>-->
        </span>
        </b-card>
        <b-card class="mt-2" sub-title="Propose to change">
        <span class="small-black-card">
          Token <span class="small-green">Aids to Navigation token</span>, ID=<span class="small-green">{{ selectedTokenID }}</span>.
          <div v-html="this.$parent.$parent.$parent.proposedInfo"/>
          <!--          {{ this.$parent.$parent.$parent.proposedInfo }}-->
          <!--          Token <span class="small-green">Aids to Navigation token</span>, ID=<span class="small-green">240</span>, attribute <span class="small-green">status</span>, change value from <span class="small-green">permanent</span> to <span class="small-green">not in use</span>-->
        </span>
        </b-card>
        <b-card class="mt-2 px-0" sub-title="Planned result">
          <span class="small-black-card">
          <span class="small-green">{{ getDescription() }}</span> in cell <span class="small-green">{{ selectedTokenCell }}</span>.
          <div v-html="this.$parent.$parent.$parent.plannedInfo"/>
            <!--          {{ this.$parent.$parent.$parent.plannedInfo }}-->
            <!--          Cell <span class="small-green">GB50182A</span>. <span class="small-green">Lighted Cardinal Buoy; spar (spindle)</span>. Name: <span class="small-green">N. Shoal</span>, coordinates: <span class="small-green">49.88507, -6.41979</span>-->
            <!--          The attribute <span class="small-green">status</span> will changed to <span class="small-green">'not in use'</span>-->
          <br><a href="#" @click="apply()">Apply</a>
          </span>
        </b-card>
      </div>

      <b-card class="mt-2" v-if="this.$parent.$parent.$parent.showLog">
      <span class="small-black-card">
        <span class="small-green">{{ getDescription() }}</span> in cell <span class="small-green">{{ selectedTokenCell }}</span>.
        <div v-html="this.$parent.$parent.$parent.resultInfo"/>
        <!--        {{ this.$parent.$parent.$parent.resultInfo }}-->
        <!--        Cell <span class="small-green">GB50182A</span>, Token <span class="small-green">Aids to Navigation Token</span><br>-->
        <!--        The attribute <span class="small-green">status</span> was changed to <span class="small-green">'not in use'</span>-->
        </span>
      </b-card>


    </div>

    <div class="mt-2" v-if="$store.state.fickle.tokens.is_ready">
      <ul class="tree">
        <li>
          <details open>
            <summary>
              Cells
            </summary>
            <ul>
              <div v-for="(item, i) in $store.state.fickle.all_tokens">
                <li>
                  <details open>
                    <summary>
                      {{ item.cell }}
                    </summary>

                    <ul>
                      <div v-for="(token, i) in $store.state.fickle.all_tokens[cellIndex(item.cell)].tokens">
                        <!--                        <li v-if="(item.cell === 'EE50823C' && token.id === '20010') || (item.cell === 'EE4D1207') || (item.cell === 'EE4D1006')">-->
                        <li v-if="($parent.$parent.$parent.messageType === '1') ||
                         ($parent.$parent.$parent.messageType === '2' && (token.id === '19281' || token.id === '162150') ||
                         ($parent.$parent.$parent.messageType === '3' && (token.id === '9036')) ||
                         ($parent.$parent.$parent.messageType === '4' && (token.id === '6041'))
                         )">
                          <details :id="item.cell + '|' + token.id">
                            <summary>
                              <span v-if="token.changed" class="normal-green">
                              {{ token.name }} ({{ token.id }})
                                </span>
                              <span v-else>
                                {{ token.name }} ({{ token.id }})
                              </span>
                              <b-icon v-if="token.changed" icon="check-all"></b-icon>
                              <!--                              {{ token.id }} {{ token.spatial._text }}-->
                            </summary>
                            <ul>
                              <div v-for="attribute in token.attributes">
                                <li>
                                  <span class="small-gray-card">{{ attribute.name }}: <a href="#" @click="showEdit(item.cell, token.id, token.key,-1, -1, attribute)">{{ attribute.val }}</a></span>
                                </li>
                              </div>
                              <div v-for="attribute in token.complex_attributes">
                                <li>
                                  <details v-if="attribute.childs.length > 0">
                                    <summary>
                                      <span class="small-gray-card">{{ attribute.attr.name }}</span>
                                    </summary>

                                    <ul>
                                      <div v-for="subattr in attribute.childs">
                                        <li>
                                          <span class="small-gray-card">{{ subattr.name }}: <a href="#" @click="showEdit(item.cell, token.id, token.key,-1, -1, subattr)">{{ subattr.val }}</a></span>
                                        </li>
                                      </div>
                                    </ul>

                                  </details>
                                </li>
                              </div>

                              <div v-for="component in token.components">
                                <li>
                                  <details>
                                    <summary>
                                      <span class="small-black-card">{{ component.name }}</span>
                                    </summary>
                                    <ul>
                                      <div v-for="attribute in component.attributes">
                                        <li>
                                          <span class="small-gray-card">{{ attribute.name }}: <a href="#" @click="showEdit(item.cell, token.id, token.key, component.id, component.key, attribute)">{{ attribute.val }}</a></span>
                                        </li>
                                      </div>

                                      <div v-for="attribute in component.complex_attributes">
                                        <li>
                                          <details v-if="attribute.childs.length > 0">
                                            <summary>
                                              <span class="small-gray-card">{{ attribute.attr.name }}</span>
                                            </summary>

                                            <ul>
                                              <div v-for="subattr in attribute.childs">
                                                <li>
                                                  <span class="small-gray-card">{{ subattr.name }}: <a href="#" @click="showEdit(item.cell, token.id, token.key,-1, -1, subattr)">{{ subattr.val }}</a></span>
                                                </li>
                                              </div>
                                            </ul>

                                          </details>
                                        </li>
                                      </div>

                                    </ul>

                                  </details>
                                </li>
                              </div>
                              <div>
                                <li>
                                  <details>
                                    <summary>
                                      <span class="small-gray-card">Coordinates</span>
                                    </summary>
                                    <ul>
                                      <li>
                                        <span class="small-gray-card">Lat: <a href="#" @click="showEditCoordinates(item.cell, token, token.components, 0)">{{ getCoordinates(token, token.components, item.cell)[0] }}</a></span>
                                      </li>
                                      <li>
                                        <span class="small-gray-card">Lon: <a href="#" @click="showEditCoordinates(item.cell, token, token.components, 1)">{{ getCoordinates(token, token.components, item.cell)[1] }}</a></span>
                                      </li>
                                    </ul>
                                  </details>
                                </li>
                              </div>

                            </ul>
                          </details>
                        </li>
                      </div>
                    </ul>


                  </details>
                </li>
              </div>


            </ul>


          </details>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Details from "@/views/ecdis/Details.vue";
import {TokenParser} from "@/views/tokens/parser";
import {eventBus} from "@/main";

export default {
  name: "TokensView",
  components: {Details},
  props: ["parsers"],
  data() {
    return {
      editObject: false,
      addObject: false,
      editableCell: '',
      editableTokenId: -1,
      editableComponentId: -1,
      editableTokenKey: -1,
      editableComponentKey: -1,
      editableObject: null,
      addingObject: null,
      addingAttributes: [],
      addingUUID: 10000000,
      selected: null,
      selectedTokenCell: '',
      selectedTokenID: '',

    }
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.details = document.querySelectorAll("details")
  //
  //     this.details.forEach((detail) => {
  //       detail.addEventListener("toggle", () => {
  //         console.log("aaaa", detail.id)
  //       });
  //     });
  //
  //   }, 2000)
  // },
  mounted() {
    eventBus.$off('prepare-tokens-details')
    eventBus.$on('prepare-tokens-details', () => {
      // alert(4)

      setTimeout(() => {
        // console.log("fcfc2")
        let details = document.querySelectorAll("details")
        let selectedDetail = ''

        details.forEach((detail) => {
          detail.addEventListener("toggle", () => {
            if (detail.id !== '' && detail.open) {
              console.log(detail.id)
              let cellName = (detail.id).split('|')[0]
              let id = (detail.id).split('|')[1]

              this.selectedTokenCell = cellName
              this.selectedTokenID = id

              let index = this.cellIndex(cellName)
              console.log(index, id)

              // let points = this.$store.state.fickle.all_tokens[index]
              // let items = _.find(points, {id: this.editableObject.componentId})

              // let points = []
              let items = _.find(this.$store.state.fickle.all_tokens, {cell: cellName})
              let token = _.find(items.tokens, {id: id})
              let spatialId = token.spatial._text
              // console.log("jj24_1", items, id)
              // console.log("jj24_2", items, token)
              // console.log("jj24_3", items, spatialId)
              // let points = this.$store.state.fickle.all_tokens[index].points[0] //TODO

              // console.log(points)

              let coordinates = _.find(items.points, {id: spatialId})

              console.log("jj24", coordinates)

              // points.push(Number(coordinates['lat']))
              // points.push(Number(coordinates['lon']))

              // if (this.$parent.$parent.$parent.mapsTabIndex === 0)
              //   eventBus.$emit('token-selected-s57', {lat: Number(coordinates['lat']), lon: Number(coordinates['lon'])})
              // else

                eventBus.$emit('token-selected-s57', {lat: Number(coordinates['lat']), lon: Number(coordinates['lon']), id:id})
                eventBus.$emit('token-selected-s101', {lat: Number(coordinates['lat']), lon: Number(coordinates['lon']), id:id})

              selectedDetail = detail.id
              this.$parent.$parent.$parent.showCommand = true
              this.$parent.$parent.$parent.showLog = false
            }

            details.forEach((detail) => {
              if (detail.id !== '' && detail.id !== selectedDetail)
                detail.open = false
            })

          });
        });


      }, 500)

    })
  },
  watch: {
    'this.$store.state.fickle.tokens.is_ready'(newVal) {
      alert(2)
    },
    '$store.state.fickle.tokens.is_ready'(newVal) {
      // alert(1)
      if (newVal) {
        // console.log("fcfc1")
        setTimeout(() => {
          // console.log("fcfc2")
          let details = document.querySelectorAll("details")
          let selectedDetail = ''

          details.forEach((detail) => {
            detail.addEventListener("toggle", () => {
              if (detail.id !== '' && detail.open) {
                console.log(detail.id)
                let cellName = (detail.id).split('|')[0]
                let id = (detail.id).split('|')[1]

                this.selectedTokenCell = cellName
                this.selectedTokenID = id

                let index = this.cellIndex(cellName)
                console.log(index, id)

                // let points = this.$store.state.fickle.all_tokens[index]
                // let items = _.find(points, {id: this.editableObject.componentId})

                // let point = []
                // let points = this.$store.state.fickle.all_tokens[index].points[0] //TODO
                //
                // console.log(points)

                // let coordinates = _.find(points, {id: id})

                // console.log(coordinates)

                // point.push(coordinates['lat'])
                // point.push(coordinates['lon'])


                // eventBus.$emit('token-selected', {lat: points.lat, lon: points.lon})
                selectedDetail = detail.id
                this.$parent.$parent.$parent.showCommand = true
                this.$parent.$parent.$parent.showLog = false
              }

              details.forEach((detail1) => {
                console.log(selectedDetail, detail1.id)
                if (detail.id !== '' && detail1.id !== '' && detail1.id !== selectedDetail) {
                  console.log("close")
                  detail1.open = false
                }
              })

            });
          });


        }, 5000)
      }
    }
  },
  methods: {
    getDescription() {
      let cellIndex = this.cellIndex(this.selectedTokenCell)
      let items = this.$store.state.fickle.all_tokens[cellIndex]
      let tokenIndex = _.findIndex(items.tokens, {id: this.selectedTokenID})
      let description = _.find(items.tokens[tokenIndex].attributes, {code: 'descriptor'})['val']

      return description
    },
    isChangedItem() {
      let cellIndex = this.cellIndex(this.selectedTokenCell)
      let items = this.$store.state.fickle.all_tokens[cellIndex]
      let tokenIndex = _.findIndex(items.tokens, {id: this.selectedTokenID})
      return items.tokens[tokenIndex].changed
    },
    cellIndex(cellName) {
      let index = _.findIndex(this.$store.state.fickle.all_tokens, {'cell': cellName})
      return index
    },
    apply() {
      this.$parent.$parent.$parent.showLog = true

      // this.$parent.$parent.$parent.autoEdit(this.selectedTokenCell)
      this.autoEdit(this.selectedTokenCell, this.selectedTokenID)

      let cellIndex = this.cellIndex(this.selectedTokenCell)
      let items = this.$store.state.fickle.all_tokens[cellIndex]
      let tokenIndex = _.findIndex(items.tokens, {id: this.selectedTokenID})
      items.tokens[tokenIndex].changed = true

      store.commit("fickle/setTokensLog", "Message: <span class=\"small-green\">" + this.$parent.$parent.$parent.currentMessage + "</span>. <br>Log: cell <span class=\"small-green\">" + this.selectedTokenCell + "</span>, token <span class=\"small-green\">Aids to Navigation token</span>, ID=<span class=\"small-green\">" + this.selectedTokenID + "</span>. " + this.$parent.$parent.$parent.resultInfo)

      this.$parent.$parent.$parent.saveFile(this.selectedTokenCell)

    },

    autoEdit(cellName, tokenID) {
      let tp = _.find(this.parsers, {cell: cellName})['parser']
      let tokens = _.find(this.$store.state.fickle.all_tokens, {cell: cellName})
      let token = _.find(tokens.tokens, {id: tokenID})

      if (tokenID === '19281' || tokenID === '162150') {
        console.log('ee1224', token)
        let obj = _.find(token.components, {code: "LightAllAround"})
        console.log('ee1224', obj)
        let attribute = _.find(obj.attributes, {code: "colour"})
        console.log('ee1224', attribute)

        tp.editData(tokenID, -1, obj.id, -1, attribute, "red")
        store.commit("fickle/editTokenAttribute", {cell: cellName, token_id: tokenID, UUID: attribute.UUID, val: "red"})
      }

      if (tokenID === '6041') {
        let component = _.find(token.components, {code: 'LightAllAround'})
        console.log("ee1", component)
        // // let obj = _.find(component.attributes, {code: "exhibitionConditionOfLight"})
        //

        console.log("ee1_1", component.complex_attributes[0])

        let obj = _.find(component.complex_attributes[0].childs, {code:"signalPeriod"})
        // console.log("ee1_1", component.complex_attributes[0])
        // let obj = null
        // component.complex_attributes.forEach(item => {
        //   console.log("ee1_1", item)
        //   if (item.childs.code === "signalPeriod") {
        //     console.log("ee1", "done")
        //     obj = item.attr
        //   }
        // })

        // let obj = _.find(component.complex_attributes.attr, {code: "rhythmOfLight"})
        console.log("ee1_2", obj)

        tp.editData(tokenID, -1, -1, -1, obj, '2')
        store.commit("fickle/editTokenAttribute", {cell: cellName, token_id: tokenID, UUID: obj.UUID, val: '2'})


      }

      if (tokenID === '9036') {

        let spatialId = token.spatial._text
        let coordinates = _.find(tokens.points, {id: spatialId})

        tp.editCoordinates(spatialId, "59.497833", "")
        tp.editCoordinates(spatialId, "", "24.322833")
        store.commit("fickle/editCoordinates", {cell: cellName, id: spatialId, lat: "59.497833", lon: ""})
        store.commit("fickle/editCoordinates", {cell: cellName, id: spatialId, lat: "", lon: "24.322833"})
      }


      tp.restoreTree(cellName)
      tp.makeDescriptor()
    },

    editDone() {

      console.log("aaaa e", this.editableObject)

      let tp = _.find(this.parsers, {cell: this.editableCell})['parser']

      if (this.editableObject.type === 'coordinates') {
        // let points = this.$store.state.fickle.all_tokens_points //TODO
        let points = this.$store.state.fickle.all_tokens[this.cellIndex(this.editableCell)].points
        let items = _.find(points, {id: this.editableObject.componentId})
        console.log("uuu", items)
        let lat = ''
        let lon = ''
        if (this.editableObject.index === 0)
          lat = this.selected
        else
          lon = this.selected

        tp.editCoordinates(this.editableObject.componentId, lat, lon)
        store.commit("fickle/editCoordinates", {cell: this.editableCell, id: this.editableObject.componentId, lat: lat, lon: lon})
      } else {
        console.log("__1")
        console.log("ee2", this.editableTokenId, this.editableTokenKey, this.editableComponentId, this.editableComponentKey, this.editableObject, this.selected)
        tp.editData(this.editableTokenId, this.editableTokenKey, this.editableComponentId, this.editableComponentKey, this.editableObject, this.selected)
        console.log("__2")
        console.log("ee2", this.editableCell, this.editableTokenId, this.editableObject.UUID, this.selected)
        store.commit("fickle/editTokenAttribute", {cell: this.editableCell, token_id: this.editableTokenId, UUID: this.editableObject.UUID, val: this.selected})

        if (this.editableObject.code !== 'descriptor') {
          tp.restoreTree(this.editableCell)
          tp.makeDescriptor()
        }

        console.log("__3")
      }

      this.$parent.$parent.$parent.saveFile(this.selectedTokenCell)
    },
    addDone() {
      console.log(this.addingObject.code, this.selected)
      store.commit("fickle/addTokenAttribute", {name: this.addingObject.name, code: this.addingObject.code, attribute: this.selected, UUID: this.addingUUID++})
    },
    deleteDone() {
      let tp = _.find(this.parsers, {cell: this.editableCell})['parser']
      tp.deleteData(this.editableTokenId, this.editableTokenKey, this.editableComponentId, this.editableComponentKey, this.editableObject)
      store.commit("fickle/deleteTokenAttribute", {cell: this.editableCell, token_id: this.editableTokenId, UUID: this.editableObject.UUID})
    },
    addAttr(feature) {
      console.log("ssss", feature)
      console.log("ssss", feature.code)
      this.addingAttributes = this.tp.getAvailableAttrubutes(feature.code)
      this.addingObject = feature
      this.addObject = true
    },
    showEdit(cell, token_id, token_key, component_id, component_key, attr) {
      console.log("gg", token_id, token_key, component_id, component_key, attr)

      this.editableCell = cell
      this.editableTokenId = token_id
      this.editableComponentId = component_id
      this.editableObject = attr
      this.selected = attr.val
      this.editObject = true
    },
    showEditCoordinates(cell, token, components, index) {
      this.editableCell = cell
      this.editableObject = {name: 'Coordinates', type: 'coordinates', componentId: components[0]['spatial']['_text'], index: index}
      this.selected = this.getCoordinates(token, components, cell)[index]
      this.editObject = true
    },
    getCoordinates(token, components, cellName) {
      let point = []
      let id = token['spatial']['_text']
      // let id = components[0]['spatial']['_text']
      let points = this.$store.state.fickle.all_tokens[this.cellIndex(cellName)].points

      // console.log("eeee24", id, points)

      let coordinates = _.find(points, {id: id})
      point.push(coordinates['lat'])
      point.push(coordinates['lon'])
      return point
      // return [1,2]
    },
    options(subattr) {
      let values = []
      subattr.availableValues["S100FC:listedValue"].forEach(item => {
        values.push({value: item['S100FC:label'], text: item['S100FC:label']})
      })
      return values
    },
    bool_options() {
      let values = ["true", "false"]
      return values
    },
  }
}
</script>

<style scoped>
.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px solid #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.tree summary::before {
  z-index: 1;
  background: #07a8e3 url('expand-collapse.svg') 0 0;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
}
</style>