<template>
  <div>
<!--    {{$store.state.fickle.weather.isUpdating}}-->
<!--    {{$store.state.fickle.weather.weatherwp.length}}-->
<!--    {{$store.state.routes.route.length}}-->

    <b-form-checkbox :disabled="weatherDisabled" v-model="showWind" name="check-button-1" switch>
      Show wind
    </b-form-checkbox>
    <b-form-checkbox :disabled="weatherDisabled" v-model="showTemperature" name="check-button-2" switch>
      Show temperature
    </b-form-checkbox>
    <b-badge v-if="weatherDisabled" variant="danger">Select date/time first</b-badge>
    <br>

    <!--  <b-form-checkbox v-model="showWind" name="check-button" switch>-->
    <!--    Show wind-->
    <!--  </b-form-checkbox>-->

    <div v-if="($store.state.fickle.weather.weatherwp.length > 0 && $store.state.fickle.weather.weatherwp[0].temperature === 'N/A') ||
                $store.state.fickle.weather.weatherwp.length === 0">
    <b-form-spinbutton style="width: 342px"
        id="sb-date"
        v-model="dateOffset"
        :formatter-fn="dayFormatter"
        min="0"
        max="2"
        wrap
    ></b-form-spinbutton>
    <b-form-spinbutton style="width: 342px"
        id="sb-time"
        v-model="timeOffset"
        :formatter-fn="timeFormatter"
        min="0"
        max="23"
        wrap
    ></b-form-spinbutton></div>

<!--    Date date: {{getDay()}}<br>-->
<!--    Date time: {{getTime()}}<br>-->
<!--    Datetime: {{getDateTime()}}-->

<!--    {{ moment().add(value, 'days').format("DD MMM") }}-->
<!--    return newdate-->
<!--    },-->
<!--    timeFormatter(value) {-->
<!--    // console.log("dddd", moment().format("DD MMM"))-->
<!--    // console.log("dddd", moment().add(1, 'days').format("DD MMM"))-->
<!--    // console.log("dddd", value)-->
<!--    let newtime = moment().add(value, 'hours').format("HH:mm");-->

<!--    <b-container>-->
<!--      <b-row>-->
<!--        <b-col>-->
<!--          Weather date: {{ weather.nowdate }}-->
<!--        </b-col>-->
<!--        <b-col>-->
<!--          <b-button-group size="sm">-->
<!--            <b-button>+</b-button>-->
<!--            <b-button>-</b-button>-->
<!--          </b-button-group>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <b-row>-->
<!--        <b-col>-->
<!--          Weather time: {{ weather.nowtime }}-->
<!--        </b-col>-->
<!--        <b-col>-->
<!--          <b-button-group size="sm">-->
<!--            <b-button>+</b-button>-->
<!--            <b-button>-</b-button>-->
<!--          </b-button-group>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <b-row>-->
<!--        <b-col>-->
<!--          Route start time: N/A-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </b-container>-->


<!--    <b-form-group class="mb-0" label-cols="2" label-size="sm" label="Date">-->
<!--      <b-form-datepicker id="sb-inline-date" :min="min" :max="max" size="sm" v-model="weather.date" placeholder="Select a date" locale="en"></b-form-datepicker>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="2" label-size="sm" label="Time">-->
<!--      <b-form-timepicker id="sb-inline-time" minutes-step="30" :hour12=false size="sm" v-model="weather.time" placeholder="Select a time" locale="en"></b-form-timepicker>-->
<!--    </b-form-group>-->

    <img v-if="showTemperature" src="tlegend.png">
    <img v-if="showWind" src="wlegend.png">

    <div v-if="($store.state.fickle.weather.weatherwp.length > 0)">
      <b-badge>Route start time: {{routeTime()}}</b-badge>
    </div>

    <b-badge variant="danger" v-if="$store.state.routes.route.length > 1 && $store.state.routes.info.length === 0">Weather is not available for the route, recalculate it</b-badge>
    <b-badge variant="danger" v-if="$store.state.fickle.weather.weatherwp.length > 0 && !isWeatherAvailable()">Weather is not available for the route</b-badge>

    <div v-if="$store.state.routes.info.length > 1 && $store.state.routes.route.length > 1 && $store.state.routes.route.length > $store.state.fickle.weather.weatherwp.length" align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading weather...</strong>
    </div>
    <div v-else>

    <b-table v-model="aaa" v-if="$store.state.fickle.weather.weatherwp.length > 0 && $store.state.routes.route.length > 0" selectable sticky-header select-mode="single" ref="wptable1" id="routes-table1" style="max-height: 400px;"
             @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items" :fields="visible_fields">
      <template #cell(name)="data">
        <span v-if="isDanger(data.index)" class="smt14">WP{{ data.index }}</span>
        <span v-else class="smt4">WP{{ data.index }}</span>
      </template>

      <template #cell(temperature)="data">
        {{ $store.state.fickle.weather.weatherwp[data.index].temperature ?? '' }}
      </template>
      <template #cell(wind)="data">
        {{ $store.state.fickle.weather.weatherwp[data.index].wind ?? '' }}
      </template>
      <template #cell(angle)="data">
        {{ $store.state.fickle.weather.weatherwp[data.index].windAngle ?? '' }}
      </template>

      <template #cell(eta)="data">
          <span v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <span v-if="$store.state.routes.route_settings.date === '' || $store.state.routes.route_settings.time === ''">
              {{ new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16) }}
            </span>
            <span v-else>
              {{ datem($store.state.routes.info[data.index].fromStartSeconds) }}
            </span>
          </span>
      </template>
    </b-table>
    </div>

  </div>
</template>

<script>


import {eventBus} from "@/main";
import store from "@/store";
import moment from 'moment';
import {myAxios} from "@/services/myAxios";
import monent from "moment";

export default {
  name: "WearherSettings",
  data() {
    // const now = new Date()
    // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // // 15th two months prior
    // const minDate = new Date(today)
    // // minDate.setMonth(minDate.getDay() - 2)
    // minDate.setDate(moment().subtract(3, 'day'))
    // // 15th in two months
    // const maxDate = new Date(today)
    // // maxDate.setMonth(maxDate.getMonth() + 2)
    // maxDate.setDate(minDate.getDay() + 3)

    let a = moment().add(3, 'days').format("YYYY-MM-DD");
    let b = moment().subtract(0, 'days').format("YYYY-MM-DD");

    return {
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      aaa: [],
      weatherInWP: [],
      showWind: false,
      showTemperature: false,
      weatherTime: "15",
      weather: {
        date: new Date().toISOString().split('T')[0],
        time: moment().format("HH:mm"),
        nowdate: moment().format("DD MMM"),
        nowtime: moment().format("HH:mm")
      },
      currentIndex: 0,
      dateOffset: 0,
      timeOffset: 0,
      weatherDisabled: false,
      min: b,
      max: a
    }
  },
  mounted() {
    this.keepRouteSettings()

    eventBus.$off("init-weather")
    eventBus.$on("init-weather", () => {
      this.showWind = false
      this.showTemperature = false
    })

    eventBus.$off('route-datetime-changed')
    eventBus.$on('route-datetime-changed', () => {

      let weather = []
      let startdate = store.state.routes.route_settings.date
      let starttime = store.state.routes.route_settings.time

      // if (!(startdate === '' || starttime === '')) {
      store.commit("fickle/setWeatherUpdating", true)
      store.commit("fickle/setWeatherWP", weather)
      let pts = this.$store.state.routes.route
      pts.forEach((coordinates, i) => {
        let lat = coordinates.lat
        let lon = coordinates.lon

        let seconds = store.state.routes.info[i].fromStartSeconds
        let datetime = moment(startdate + " " + starttime, "YYYY-MM-DD HH:mm")
        // let datetimewp = datetime.add(seconds, "seconds")
        // console.log("jjj", i, lat, lon, datetime.unix())

        let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetime.unix()

        myAxios.get(URL).then(resp => {
          // console.log("jjja", resp.data)
          weather.push(resp.data)
        }).catch(error => {
          // console.log("jjja1", error)
          weather.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'})
        })

        store.commit("fickle/setWeatherWP", weather)
      })
      store.commit("fickle/setWeatherUpdating", false)


      store.commit("fickle/setWeatherDateTime", this.dateu(this.$store.state.routes.info[this.currentIndex].fromStartSeconds))
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    })

    // console.log("jjj", "---------------------------")
    // console.log("jjj", this.items)
    // this.items.forEach((wp, i) => {
    //   let lat = wp.lat
    //   let lon = wp.lon
    //   // let time = dateu($store.state.routes.info[data.index].fromStartSeconds)
    //   console.log("jjj", lat, lon,  i)
    // })

    eventBus.$off('clear-current-wp-index-weather')
    eventBus.$on('clear-current-wp-index-weather', () => {
      this.currentRowIndex = -1
    })

    eventBus.$off('move-current-weather')
    eventBus.$on('move-current-weather', () => {
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$off('move-next-weather')
    eventBus.$on('move-next-weather', () => {
      console.log("qqq", this.currentRowIndex)

      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      // this.map.closePopup();

      // eventBus.$emit('switch-popup', this.currentRowIndex)
      // eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.centerRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })

    eventBus.$off('move-prev-weather')
    eventBus.$on('move-prev-weather', () => {
      // console.log("aaa", this.currentRowIndex, this.focusWPIndex)
      // console.log(this.currentRowIndex)
      // console.log(this.prevRowIndex)
      // console.log(this.focusWPIndex)
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
        console.log("eee1")
      } else {
        this.selectRow(this.focusWPIndex)
        console.log("eee2", this.focusWPIndex)
      }

      // this.map.closePopup();

      // eventBus.$emit('switch-popup', this.currentRowIndex)
      // eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.centerRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })

    eventBus.$off('select-routetable-row-weather')
    eventBus.$on('select-routetable-row-weather', (id) => {
      console.log("eee", id)
      this.currentRowIndex = id
      this.$refs.wptable1.selectRow(id);
      const tbody = this.$refs.wptable1.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      // row.scrollIntoView()
      row.scrollIntoView({block: 'center'})
      // eventBus.$emit('show-wp-index', id)
    })

    eventBus.$off('set-focus-index-weather')
    eventBus.$on('set-focus-index-weather', (index) => {
      this.focusWPIndex = index
    })

  },
  watch: {
    'showWind'(newVal, oldVal) {
      if (newVal)
        this.showTemperature = false
      this.keepRouteSettings()
      if (this.showWind)
        eventBus.$emit('change-weather')

      if (!this.showWind && !this.showTemperature)
        eventBus.$emit('return-nogo')
    },
    'showTemperature'(newVal, oldVal) {
      if (newVal)
        this.showWind = false

      this.keepRouteSettings()
      if (this.showTemperature)
        eventBus.$emit('change-weather')

      if (!this.showWind && !this.showTemperature)
        eventBus.$emit('return-nogo')
    },
    'dateOffset'() {
      this.weatherDisabled = false
      this.keepRouteSettings()
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    },
    'timeOffset'() {
      this.weatherDisabled = false
      this.keepRouteSettings()
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    }
  },
  computed: {
    // getWeather(lat, lng, time) {
    //   let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lng + '&t=' + time
    //
    //   myAxios.get(URL).then(resp => {
    //     console.log(resp.data)
    //     setTimeout(() => {
    //       this.$refs.wptable1.refresh();
    //     }, 1000);
    //     return resp.data
    //   })
    // },
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'eta', label: 'ETA', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'temperature', label: 'T °C', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'wind', label: 'Wind m/s', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'angle', label: 'Direction °', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {

    isWeatherAvailable() {
      let val = false
      this.$store.state.fickle.weather.weatherwp.forEach(item => {
        if (item.temperature !== 'N/A') {
          val = true
        }
      })

      return val
    },

    routeTime() {
      let date = this.$store.state.routes.route_settings.date
      let time = this.$store.state.routes.route_settings.time
      // let datetime = moment().day(date).hour(time)
      let datetime = moment(date + " " + time).format("DD MMM HH:mm")
      if (datetime === "Invalid date")
        datetime = "N/A"
      return datetime
    },

    dayFormatter(value) {
      // console.log("dddd", moment().format("DD MMM"))
      // console.log("dddd", moment().add(1, 'days').format("DD MMM"))
      // console.log("dddd", value)
      let newdate = moment().add(value, 'days').format("DD MMM");
      return newdate
    },
    timeFormatter(value) {
      // console.log("dddd", moment().format("DD MMM"))
      // console.log("dddd", moment().add(1, 'days').format("DD MMM"))
      // console.log("dddd", value)
      let newtime = moment().add(value, 'hours').format("HH:mm");
      return newtime
    },
    getDay() {
      return moment().add(this.dateOffset, 'days').format("DD MMM");
    },
    getTime() {
      return moment().add(this.timeOffset, 'hours').format("HH:mm");
    },
    getDateTime() {
      let date = moment().add(this.dateOffset, 'days')
      let hours = moment(date).add(this.timeOffset, 'hours').hours()
      let datetime = moment(date.hours(0)).add(hours, 'hours').unix()
      // let datetime = moment(date.hours(0)).add(hours, 'hours').format("DD MMM HH:mm");
      return datetime
    },

    getWeather(lat, lng, time) {
      console.log("weather", time, monent.unix(time).format("DD/MM/YYYY"));
      let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lng + '&t=' + time

      myAxios.get(URL).then(resp => {
        return resp.data
      })
    },
    selectRow(index) {
      // alert(index)
      // alert('aaa')
      this.$refs.wptable1.unselectRow(index);
      setTimeout( () => {
        this.$refs.wptable1.selectRow(index);
      }, 100)
//      this.$refs.wptable.selectRow(index);
      // this.scrollToRow(index);
      this.currentRowIndex = index

      // eventBus.$emit('show-wp-index', index)
      eventBus.$emit('click-wp', index)

      // this.rowsToSelect[index].click()
      // console.log(index, this.rowsToSelect[index], this.currentRowIndex, 'wpin')
    },
    onRowClicked(row, index) {
      console.log(index)
      console.log(this.dateu(this.$store.state.routes.info[index].fromStartSeconds))

      this.currentIndex = index
      if (this.$store.state.fickle.weather.weatherwp.length > 0 && this.$store.state.fickle.weather.weatherwp[0].temperature !== 'N/A') {

        store.commit("fickle/setWeatherDateTime", this.dateu(this.$store.state.routes.info[index].fromStartSeconds))
        if (this.showWind || this.showTemperature)
          eventBus.$emit('change-weather')
      }
    },
    onRowSelected(items) {
      eventBus.$emit('wp-selected', items[0])
    },

    keepRouteSettings() {
      console.log("aaa", this.getDateTime())

      store.commit('fickle/setWeatherSettings', {
        showWind: this.showWind, showTemperature: this.showTemperature, datetimeu: this.getDateTime()
        // date: this.weather.date, time: this.weather.time
      })
    },
    items() {
      return this.$store.state.routes.route
    },
    isDanger(index) {
      let dangers = this.$store.state.routes.dangers.filter(function (e) {
        return e.isDanger === true && e.legIndex === index
      });
      return dangers.length > 0
    },
    datem(seconds) {
      let date = this.$store.state.routes.route_settings.date
      let time = this.$store.state.routes.route_settings.time
      let datetime = moment(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")

      return shortdate
    },
    dateu(seconds) {
      let date = this.$store.state.routes.route_settings.date
      let time = this.$store.state.routes.route_settings.time
      let datetime = moment(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.unix()

      return shortdate
    },

  }

}
</script>

<style scoped>

</style>