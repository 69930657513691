<template>
  <div>
    <b-card v-if="$store.state.routes.route.length > 0" class="mx-0 small-black" bg-variant="light" text-variant="black">
      <div v-if="$store.state.routes.length_manual < 1000">
        <span class="small-gray-card">Length (m):</span> {{ $store.state.routes.length_manual }}<br>
      </div>
      <div v-else>
        <span class="small-gray-card">Length (nm):</span> {{ ($store.state.routes.length_manual * 0.000539957).toFixed(2) }}<br>
      </div>
      <div v-if="isDangerous() === true">
        <b-badge variant='danger'>Route isn't safe</b-badge>
        <br>
      </div>
      <div v-if="isDangerous() === false">
        <b-badge variant='success'>Route is safe</b-badge>
        <br>
      </div>
      <div v-if="isDangerous() === null">
        <b-badge variant='secondary'>Safety is unknown</b-badge>
        <br>
      </div>

      <details v-if="$store.state.routes.debug.length > 0">
        <summary>
          Timing info
        </summary>
        <ul>
          <div v-for="(item) in $store.state.routes.debug">
            <li>
              {{ item.name }}: {{formatTime(item.ellapsed)}}
            </li>
          </div>
        </ul>
      </details>

    </b-card>
  </div>
</template>

<script>

import Details from "@/views/ecdis/Details.vue";
import moment from "moment";

export default {
  components: {Details},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    formatTime(msecs) {
      return moment.utc(msecs).format('mm:ss');
    },
    isDangerous() {
      if (this.$store.state.routes.dangers.length === 0)
        return null

      let val = false;
      for (let i = 0; i < this.$store.state.routes.dangers.length; i++) {
        if (this.$store.state.routes.dangers[i].isDanger) {
          val = true;
          break;
        }
      }
      return val;
    }
  }
}
</script>
