import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {file} from "paths.macro";
// import json from "@/store/modules/_a_installations.json";
// import json1 from "@/store/modules/_a_installations.json";
import {eventBus} from "@/main";
// import json2 from "@/store/modules/_a_transactions.json";
import Vue from "vue";
import {addIndexToArray} from "@/utils/utils";
import * as utils from "@/utils/utils";
import store from "@/store";
import router from "@/router";
import {commit} from "lodash/seq";
import localforage from "localforage";

function isStorageExist(){
    localforage.getItem('proto').then(function(value) {
        console.log("loc", true)
        return true
    }).catch(function(err) {
        return false
    });
}

function getStorage(){
    console.log("loc get")
    localforage.getItem('proto').then(function(value) {
        console.log("loc", value)
        return value
    }).catch(function(err) {
        console.log("loc", null)
        return null
    });
}

function getStorage1(){
    return initialState
    console.log("loc get")
    localforage.getItem('proto').then(function(value) {
        console.log("loc", value)
        // return value
        return initialState
    }).catch(function(err) {
        console.log("loc", null)
        return initialState
    });
}

const initialState1 = {
    ship_selected: true,
    restoredOrder: 12341234,
    order: {
        step: "aaaa",
    }
}


const initialState = {
    ship_selected: false,
    restoredOrder: null,
    order: {
        step: "",
        update: false,
        order_status: "request",
        order_number: null,
        order_date: null,
        device_id: null,
        application_id: null,
        ecdis_name: null,
        ecdis_imo: null,
        total_price: null,
        total_priceNet: null,
        total_priceAdd: null,
        total_priceRUB: null,
        total_priceAgreementRUB: null,
        total_priceAgreementUSD: null,
        cells: [],
        period: null,
        customer: null,
        product: null,
        payment: null,
        bands: [],
        // agreement: {},
        products: [],
    },
    order_payment: {
        step: "",
        update: false,
        order_status: "request",
        order_number: null,
        order_date: null,
        device_id: null,
        application_id: null,
        ecdis_name: null,
        ecdis_imo: null,
        total_price: null,
        total_priceNet: null,
        total_priceAdd: null,
        total_priceRUB: null,
        total_priceAgreementRUB: null,
        total_priceAgreementUSD: null,
        cells: [],
        period: null,
        customer: null,
        product: null,
        payment: null,
        bands: [],
        agreement: {},
        products: [],
    },
    agreement: {
        exist: false,
        buzy: false,
        id: -1,
        displayId: '',
        distributor: '',
        email: '',
        signedAt: '',
        validUntil: '',
        currency: ''
    },
    agreementV2: {
        exist: false,
        buzy: false,
        agreements: []
    },
    ecdis_charts: [],
    ecdis_charts1: [],


    temp: 1,
    // installations: ["-1"],
    installationsECDIS: ["-1"],
    installationsDevices: ["-1"],
    transactions: [],
    a1: [],
    a2: [],
    selected_ecdis: null,
    order_number: null,
    order_status: "request",
    order_state: "submit",
    request_placed: false,
    show: false,
}

export const proto = {
    namespaced: true,
    // state: initialState,
    state: localStorage.getItem('proto') ? JSON.parse(localStorage.getItem('proto')) :
        initialState,

        // isStorageExist() ? getStorage() : initialState,
    // getStorage1(),

        // localforage.getItem('proto') ? JSON.parse(localforage.getItem('proto')) :
        // initialState,
    actions: {
        loadStorage({dispatch, commit}) {
            localforage.getItem('proto').then(function(value) {
                console.log("loc", value)
                commit("setLoadedStorage", value)
            })

            commit("setLoadedStorage")
        },
        selectShip({dispatch, commit}, flag) {
            return new Promise(resolve => {
                commit("setSelectShip", flag)
                resolve()
            })
        },
        getInstallations({dispatch, commit}) {
            // alert("getInstallations")
            return new Promise(resolve => {

                // alert(2)
                let URL = "api/v1/installations";
                myAxiosRetry.get(URL).then(resp => {
                    let instList = resp.data.userInfos;

                    let instECDIS = instList.filter(function (e) {
                        return e.appState !== null && e.applicationId === 11
                    });

                    let instDevices = instList.filter(function (e) {
                        return e.appState !== null && e.applicationId !== 11
                    });

                    // instList = addIndexToArray(instList);
                    instECDIS = addIndexToArray(instECDIS);
                    instDevices = addIndexToArray(instDevices);

                    commit("setInstallations", {ecdisList: instECDIS, devicesList: instDevices})
                    resolve();
                });

            })
        },
        getAgreementsV2({dispatch, commit}) {
            // alert("getAgreementsV2")
            commit("setAgreementBuzy", true)
            return new Promise(resolve => {
                let URL = "api/v2/agreement";

                // myAxios.get(URL).then(resp => {
                //     commit("setAgreementsV2", resp.data)
                //     commit("setAgreementBuzy", false)
                //     resolve();
                // });


                if (!store.state.fickle.agreement.is_downloaded) {
                    myAxiosRetry.get(URL).then(resp => {
                        commit("setAgreementsV2", resp.data)
                        commit("setAgreementBuzy", false)
                        resolve();
                    });
                } else {
                    commit("setAgreementsV2", store.state.proto.agreementV2.agreements)
                    commit("setAgreementBuzy", false)
                    resolve()
                }
            })
        },
        getAgreement({dispatch, commit}, payload) {
            // alert(payload.deviceId)
            // alert(payload.appType)
            commit("setAgreementBuzy", true)

            let URL = "api/v1/agreement/" + payload.deviceId + "/" + payload.appType;
            console.log("a++", URL)
            myAxios.get(URL).then(resp => {
                console.log("a++", resp.data)

                let x1 = [
                    {
                        "id": 13,
                        "distributorId": 12,
                        "signedAt": "2023-09-09T13:44:17.047",
                        "validTill": "2024-09-07T21:00:00",
                        "type": "com",
                        "distributorTitle": "SAB",
                        "distributorEmail": "sab@mkart.fi",
                        "distributorUserId": 93384,
                        "currency": "RUB"
                    }
                ]
                let x2 = [
                    {
                        "id": 14,
                        "distributorId": 12,
                        "signedAt": "2023-09-09T13:44:17.047",
                        "validTill": "2024-09-07T21:00:00",
                        "type": "com",
                        "distributorTitle": "SAB",
                        "distributorEmail": "sab@mkart.fi",
                        "distributorUserId": 93384,
                        "currency": "USD"
                    }
                ]


                commit("setAgreement", resp.data)
                // commit("setAgreement", x1)
                commit("setAgreementBuzy", false)
            });
        },

        // let chartsCatalog = this.$store.state.charts.s63.primar_map_info
        // let objIndex, band
        // this.cellsBands = [0,0,0,0,0,0]
        // cellsList.forEach((item) => {
        //     objIndex = chartsCatalog.findIndex((obj => obj.id === item.id));
        //     band = chartsCatalog[objIndex].usageBand
        //     // let bandName = utils.getBandName(band)
        //     // console.log(band, bandName)
        //
        //     this.cellsBands[band - 1] = this.cellsBands[band - 1] + 1
        //
        // });

        getECDISCharts({dispatch, commit}, payload) {
            let URL = "api/v1/primar-charts/" + payload.deviceId;
            myAxios.get(URL).then(resp => {

                let charts = [];
                let chartsCatalog = store.state.charts.s63.primar_map_info


                resp.data.forEach((element, i) => {
                    let objIndex = chartsCatalog.findIndex((obj => obj.id === element));

                    console.log(chartsCatalog.length)
                    console.log(chartsCatalog[0])
                    console.log(element, objIndex)

                    let title = chartsCatalog[objIndex].title
                    let band = chartsCatalog[objIndex].usageBand
                    let bandName = utils.getBandName(band)


                    charts.push({id: element, title: title, band: bandName})
                })

                commit("setECDISCharts", charts)
            });
        },
        addEcdis({dispatch, commit}, data) {
            let URL = "/api/v1/ecdis/register";
            return new Promise(resolve => {

                myAxios.post(URL, data).then(resp => {
                        if (resp.status === 200) {
                            store.commit('proto/clearInstallations')
                            store.dispatch('proto/getInstallations').then(() => {
                                resolve(true)
                            });
                        } else {
                            resolve(false)
                        }
                })

            })
        },
        updateEcdis({dispatch, commit}, data) {
            let URL = "/api/v1/ecdis/update";
            return new Promise(resolve => {

                myAxios.post(URL, data).then(resp => {
                    if (resp.status === 200) {
                        store.commit('proto/clearInstallations')
                        store.dispatch('proto/getInstallations').then(() => {
                            resolve(true)
                        });
                    } else {
                        resolve(false)
                    }
                })

            })
        },
        updateOrderStatus({dispatch, commit}, payload) {
            return new Promise(resolve => {
                let URL = "api/v2/order/" + payload.order_id + "/update-status/" + payload.status
                myAxios.post(URL).then(resp => {
                    resolve(1)
                })
            })
        },
        updateItems({dispatch, commit}, payload) {
            return new Promise(resolve => {
                let URL      =           "/api/v2/order/"+ store.state.proto.order.order_number +"/update-items"

                myAxios.post(URL, payload).then(resp => {
                    resolve(resp)
                })

            })
        },
        updateOrder({dispatch, commit}, payload) {
            return new Promise(resolve => {
                commit("setOrder", payload)
                if (payload.update) console.log(payload.update);
                resolve()

                // setTimeout(() => {
                //     commit("setOrder", payload)
                //     resolve()
                // }, 2000);

            })
        },
        cancelOrder({dispatch, commit}) {
            commit("cancelOrder")
        },
        addTransaction({dispatch, commit}, order) {
            commit("setTransactions", order)
        },
        // updateProto1({dispatch, commit}) {
        //     // let json1 = require("./_a_transactions1.json")
        //     // commit("setTransactions", json1)
        //     eventBus.$emit('refresh-orders-table');
        // },
        // updateProto2({dispatch, commit}) {
        //     // let json1 = require("./_a_transactions2.json")
        //     // commit("setTransactions", json1)
        //     eventBus.$emit('refresh-orders-table');
        // },
        keepOrderToPayment({dispatch, commit}) {
            return new Promise(resolve => {
                commit("keepOrder")
                resolve()
            })
        }
        // updateSelectedEcdis({dispatch, commit}) {
        //
        // }
    },
    mutations: {
        setLoadedStorage(state, value) {
            if (!value)
                return

            let newState = JSON.parse(value)
            Object.assign(state, newState)

            // Object.keys(state).forEach(key => {
            //     if (newState[key] !== undefined) {
            //         state[key] = newState[key]
            //     }
            // });
        },
        setRestoredOrder(state, payload){
            state.restoredOrder = payload;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));
        },
        clearProto(state){
           Object.assign(state, initialState)
        },
        setOrder(state, payload) {
            if (payload.editing)
                state.order.editing = payload.editing
            if (payload.order_number)
                state.order.order_number = payload.order_number
            if (payload.order_date)
                state.order.order_date = payload.order_date
            if (payload.order_status)
                state.order.order_status = payload.order_status
            if (payload.order_date)
                state.order.order_date = payload.order_date
            if (payload.device_id)
                state.order.device_id = payload.device_id
            if (payload.application_id !== undefined)
                state.order.application_id = payload.application_id
            if (payload.ecdis_name)
                state.order.ecdis_name = payload.ecdis_name
            if (payload.ecdis_imo)
                state.order.ecdis_imo = payload.ecdis_imo
            if (payload.cells)
                state.order.cells = payload.cells
            if (payload.period)
                state.order.period = payload.period
            if (payload.total_price)
                state.order.total_price = payload.total_price
            if (payload.total_priceNet)
                state.order.total_priceNet = payload.total_priceNet
            if (payload.total_priceAdd)
                state.order.total_priceAdd = payload.total_priceAdd
            if (payload.total_priceRUB)
                state.order.total_priceRUB = payload.total_priceRUB
            if (payload.total_priceAgreementRUB)
                state.order.total_priceAgreementRUB = payload.total_priceAgreementRUB
            if (payload.total_priceAgreementUSD)
                state.order.total_priceAgreementUSD = payload.total_priceAgreementUSD
            if (payload.step)
                state.order.step = payload.step
            if (payload.update != undefined){
                state.order.update = payload.update
            }

            if (payload.customer)
                state.order.customer = payload.customer
            if (payload.product)
                state.order.product = payload.product
            if (payload.payment)
                state.order.payment = payload.payment
            if (payload.bands)
                state.order.bands = payload.bands

            // state.order.agreement = {
            //     "currency": "RUB",
            //     "distributorName": "Ellcom Co.Ltd",
            //     "id": 12,
            //     "name": null,
            //     "signedAt": "2023-06-23T10:37:19.687",
            //     "type": "com",
            //     "validTill": "2024-02-24T21:00:00"
            // }

            state.order.products[0] =
                {
                    "id": 5105,
                    "items": [
                        {}
                    ],
                    "netPrice": 0,
                    "price": 0,
                    "provider": "Primar",
                    "type": "s63 maps"
                }
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));


        },

        keepOrder(state) {
            // alert("1234")
            state.order_payment.device_id = state.order.device_id
            state.order_payment.application_id = state.order.application_id
            state.order_payment.total_price = state.order.total_price
            state.order_payment.total_priceNet = state.order.total_priceNet
            state.order_payment.total_priceAdd = state.order.total_priceAdd
            state.order_payment.total_priceRUB = state.order.total_priceRUB
            state.order_payment.total_priceAgreementRUB = state.order.total_priceAgreementRUB
            state.order_payment.total_priceAgreementUSD = state.order.total_priceAgreementUSD
            state.order_payment.agreement.id = state.agreement.id
            state.order_payment.agreement.displayId = state.agreement.displayId
            state.order_payment.agreement.exist = state.agreement.exist
            state.order_payment.agreement.validUntil = state.agreement.validUntil
            state.order_payment.cells = state.order.cells
            state.order_payment.period = state.order.period
            state.order_payment.customer = state.order.customer
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },

        cancelOrder(state) {
            state.order.step = ""
            state.order.update = false
            state.order.order_status = "request"
            state.order.order_number = null
            state.order.order_date = null
            state.order.device_id = null
            state.order.application_id = null
            state.order.ecdis_name = null
            state.order.ecdis_imo = null
            state.order.total_price = null
            state.order.total_priceNet = null
            state.order.total_priceAdd = null
            state.order.total_priceRUB = null
            state.order.total_priceAgreementRUB = null
            state.order.total_priceAgreementUSD = null
            state.order.cells = []
            state.order.period = null
            state.customer = null
            state.product = null
            state.payment = null
            state.bands = []
            state.agreement = {}
            state.agreementV2 = {}
            state.products = []
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        cancelOrderButKeepShip(state) {
            // state.order.step = ""
            // state.order.order_status = "request"
            // state.order.order_number = null
            // state.order.order_date = null
            // state.order.device_id = null
            // state.order.application_id = null
            // state.order.ecdis_name = null
            // state.order.ecdis_imo = null
            state.order.total_price = null
            state.order.total_priceNet = null
            state.order.total_priceAdd = null
            state.order.total_priceRUB = null
            state.order.total_priceAgreementRUB = null
            state.order.total_priceAgreementUSD = null
            state.order.cells = []
            state.order.period = null
            // state.customer = null
            state.product = null
            state.payment = null
            state.bands = []
            // state.agreement = {}
            state.products = []
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setSelectShip(state, flag) {
          state.ship_selected = flag;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setInstallations(state, payload) {
            // state.installations = payload.list;
            state.installationsECDIS = payload.ecdisList;
            state.installationsDevices = payload.devicesList;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));
        },
        clearInstallations(state, json) {
            // state.installations[0] = "-1";
            state.installationsECDIS[0] = "-1";
            state.installationsDevices[0] = "-1";
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));
        },
        setECDISCharts(state, data) {
            state.ecdis_charts = data;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setAgreementBuzy(state, flag) {
            state.agreement.buzy = flag;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));
        },
        setAgreementBuzyV2(state, flag) {
            state.agreementV2.buzy = flag;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setAgreement(state, agreement) {
            if (agreement.length > 0) {
                Vue.set(state.agreement, "exist", true);
                state.agreement.exist = true;
                state.agreement.id = agreement.at(0).id;
                state.agreement.displayId = agreement.at(0).displayId;
                state.agreement.distributor = agreement.at(0).distributorTitle;
                state.agreement.email = agreement.at(0).email;
                state.agreement.signedAt = agreement.at(0).signedAt;
                state.agreement.validUntil = agreement.at(0).validTill;
                state.agreement.currency = agreement.at(0).currency;
            } else {
                Vue.set(state.agreement, "exist", false);
            }
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setAgreementsV2(state, list) {

            console.log('aa', list)

            state.agreementV2.agreements = list
            store.commit("fickle/setAgreementsDownloaded", true)
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

            // alert(state.order.device_id)
            let obj = null;
            if (list.length > 0){
                list.forEach(agre => {
                    if (obj) return;
                    agre.users.forEach(user => {
                        if (state.order.device_id == user.deviceId){
                            obj = agre;
                            obj.user = user
                            return;
                        }
                    })
                })
            }
            if (obj){
                state.agreement.id = obj.id;
                state.agreement.displayId = obj.displayId;
                state.agreement.currency = obj.currency;
                state.agreement.distributor = obj.name;
                state.agreement.validUntil = obj.validTill;
                state.agreement.exist = true;
            }
            else state.agreement.exist = false;

            // let objIndex = list.findIndex((obj => obj.deviceId === state.order.device_id));


            // if device_id in list
            // fill state.agreement, exist = true
            // else exist = false
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));
        },
        setTransactions(state, order) {
            console.log("ddddddddddddd")
            console.log("ddddddddddddd")
            console.log(order)
            return new Promise(resolve => {
                console.log("--------------- a2")
                console.log(order)

                let cloneOrder = Object.assign({}, order);

                state.transactions.push(cloneOrder);
                localStorage.setItem('proto', JSON.stringify(state));
                localforage.setItem('proto', JSON.stringify(state));

                resolve()
            })


        },
        setSelectedECDIS(state, payload) {
            state.selected_ecdis = payload;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setOrderNumber(state) {
            state.order_number = "1248"
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setOrderStatus(state, status) {
            state.order.order_status = status;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        // setOrderState(state, status) {
        //     state.order_state = status;
        // },
        setRequestPlaced(state, flag) {
            state.request_placed = flag;
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setA1(state) {
            state.transactions[0].order_status = 'Order'
            state.transactions[0].messages = [{date: '18-07-23', text: "The request changed to order"}]
            state.a1 = [{date: '18-07-23', text: "The request changed to order"}]
            state.temp = 2
            // Vue.set(state.transactions, 0, x);
            eventBus.$emit('refresh-messages-table')
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        },
        setA2(state) {
            state.transactions[0].order_status = 'Completed'
            state.transactions[0].messages = [{date: '18-07-23', text: "The request changed to order"}, {date: '18-07-23', text: "The order was paid"}]
            state.transactions[0].delivery = [{date: '18-07-23', link: "https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a"}]
            state.a1 = [{date: '18-07-23', text: "The request changed to order"}, {date: '18-07-23', text: "The order was paid"}]
            state.a2 = [{date: '18-07-23', link: "https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a"}]
            state.temp = 3
            eventBus.$emit('refresh-messages-table')
            eventBus.$emit('refresh-delivery-table')
            localStorage.setItem('proto', JSON.stringify(state));
            localforage.setItem('proto', JSON.stringify(state));

        }
    }
}