<template>
  <div>
    <div v-for="(item) in $store.state.fickle.tokens.log">
      <b-card class="mt-2">
        <span class="small-black-card">
          <div v-html="item"/>
        </span>
      </b-card>

    </div>
<!--    <b-card class="mt-2">-->
<!--      <span class="small-black-card">-->
<!--        Cell <span class="small-green">GB50182A</span>, Token <span class="small-green">Aids to Navigation Token</span><br>-->
<!--        The attribute <span class="small-green">status</span> was changed to <span class="small-red">'not in use'</span>-->
<!--        </span>-->
<!--    </b-card>-->

<!--    <b-card class="mt-2">-->
<!--      <span class="small-black-card">-->
<!--        Cell <span class="small-green">GB50182B</span>, Token <span class="small-green">Aids to Navigation Token</span><br>-->
<!--        The attribute <span class="small-green">status</span> was changed to <span class="small-red">'not in use'</span>-->
<!--        </span>-->
<!--    </b-card>-->
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "TokenLog",
}
</script>

<style scoped>

</style>