// import Manage from "@/views/manage";
import Login from "@/views/Login.vue";
// import Devices from "@/views/devices";
import Routes from "@/views/routes";
import Charts from "@/views/charts";
import Ecdis from "@/views/ecdis";
import ShipOnMap from "@/views/maps"
import XmlToken from "@/views/tokens";


import Order from "@/views/proxy-orders"
import Route from "@/views/proxy-routes"
import PaymentStatus from "@/views/PaymentStatus";
import {
    ActiveTab
} from "@/utils/config";
import Loader from "@/views/paymentBuffers/Loader";
import Mobile from "@/views/Mobile";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import Ships from "@/views/ships"

import Transactions from "@/views/transactions";
import Agreement from "@/views/agreement";

export default [{
        id: 0,
        path: '/',
        name: 'home',
        redirect: IS_GETENC? 'transactions' : 'devices',
        meta: {
            guest: false
        }
    },
    {
        id: 1,
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        },
        props: (route) => ({ mconnect: route.query.mconnect, xxx: route.name })
    },
    // {
    //     id: 2,
    //     path: '/devices',
    //     name: 'devices',
    //     components: {
    //         default: Devices
    //     },
    //     meta: {
    //         id: ActiveTab.DEVICES
    //     },
    // },
    {
        id: 3,
        path: '/charts',
        name: 'charts',
        components: {
            default: Charts
        },
    },
    // {
    //     id: 4,
    //     path: '/manage',
    //     name: 'manage',
    //     component: Manage,
    //     meta: {
    //         guest: false,
    //         id: ActiveTab.MANAGE
    //     },
    // },
    {
        id: 5,
        path: '/payment-status/:status',
        name: 'payment-status',
        component: PaymentStatus
    },
    {
        id: 6,
        component: Loader,
        path: "/loader",
        name: 'loader',
        // props: true
    },
    {
        id: 7,
        component: Mobile,
        path: "/mobile",
        name: 'mobile',
        meta: {
            guest: true
        },
        props: (route) => ({ username: route.query.u, password: route.query.p })
    },
    {
        id: 8,
        path: '/routes',
        name: 'routes',
        components: {
            default: Routes
        },
        meta: {
            guest: true,
            id: ActiveTab.ROUTES
        },
    },
    {
        id: 9,
        path: '/ecdis',
        name: 'ecdis',
        components: {
            default: Ecdis
        },
        meta: {
            guest: false,
        },
    },
    // {
    //     id: 10,
    //     path: '/viewer',
    //     name: 'viewer',
    //     components: {
    //         default: Viewer
    //     },
    //     meta: {
    //         guest: true,
    //     },
    // },
    // {
    //     id: 11,
    //     path: '/distributor',
    //     name: 'distributor',
    //     component: Distributor,
    //     meta: {
    //         guest: false,
    //         id: ActiveTab.DISTRIBUTOR
    //     },
    // },
    {
        id: 12,
        path: '/transactions',
        name: 'transactions',
        component: Transactions,
        meta: {
            guest: false,
            id: ActiveTab.TRANSACTIONS
        },
    },
    // {
    //     id: 13,
    //     component: Pdf,
    //     path: "/pdf",
    //     name: 'pdf',
    //     meta: {
    //         guest: true
    //     },
    // },
    {
        id: 14,
        component: Ships,
        path: "/ships",
        name: 'ships',
        meta: {
            guest: false
        },
    },
    {
        id: 15,
        component: Order,
        path: "/proxy-orders",
        name: 'proxy-orders',
        meta: {
            guest: false
        },
    },
    {
        id: 16,
        component: Agreement,
        path: "/agreement",
        name: 'agreement',
        meta: {
            guest: false
        },
    },
    {
        id: 17,
        component: ShipOnMap,
        path: "/shiponmap",
        name: 'shiponmap',
        meta: {
            guest: false
        },
    },
    {
        id: 18,
        component: Route,
        path: "/proxy-routes",
        name: 'proxy-routes',
        meta: {
            guest: false
        },
    },
    {
        id: 19,
        component: XmlToken,
        path: "/tokens",
        name: 'tokens',
        meta: {
            guest: true
        },

    },
]