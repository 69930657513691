<template>
  <div>

<!--    {{visibleRows1()}}-->

    <!--  <b-sidebar width="700px" id="imported-sidebar" shadow>-->
    <!--    <div class="px-3 py-2">-->
    <!--      <b-badge variant="light">IMPORTED ORDER</b-badge><br><br>-->
    <!--      <b-card>-->
    <!--        <b-row class="mb-2">-->
    <!--          <b-col sm="12">-->
    <!--              <span class="smt2s">-->
    <!--                  <b-table striped :items=imported_order :fields="imported_fields"></b-table>-->
    <!--              </span>-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-card>-->
    <!--    </div>-->
    <!--  </b-sidebar>-->

    <b-modal v-model="modal_warning" title="Warning" ok-only>
      <p>
        The order is outdated
      </p>
    </b-modal>
    <q-r-window></q-r-window>



<!--    <ship :data=current.data></ship>-->
<!--    <payment :data=current.data></payment>-->
<!--    <price :data=current.data></price>-->
<!--    <status :data=current.data></status>-->
<!--    <items :data=current.data></items>-->
<!--    <items-edit :data=current.data></items-edit>-->
<!--    <new-order></new-order>-->
<!--    <charts></charts>-->
<!--    <messages :data=current.data></messages>-->
    <items :data=current.data></items>
    <delivery :data=current.data></delivery>
    <updates :data=current.data></updates>

    <b-overlay :show="show_spinner" rounded="sm" spinner-variant="danger">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>
        </div>
      </template>

<!--      <b-button size="sm" class="mb-3 ml-1" variant="warning" @click="backToOrder()">Order</b-button>-->
      <b-row>
        <b-col>
          <b-button size="sm" class="mb-3 ml-1" variant="warning" @click="newOrder()">Add New Order</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button size="sm" class="mb-3 ml-1" variant="info" @click="readQR()">QR ECDIS</b-button>
        </b-col>
      </b-row>

      <!--  <b-dropdown split size="sm" variant="success" text="Add request">-->
      <!--    <b-dropdown-item @click="uploadFile()">From Primar CSV</b-dropdown-item>-->
      <!--    <b-dropdown-item>From Excel</b-dropdown-item>-->
      <!--    <b-dropdown-item>From Map</b-dropdown-item>-->
      <!--  </b-dropdown>-->
      <!--      id="orderstable"-->
      <b-table v-if="!show_spinner"
          ref="orderstable1"
          class="secondary"
          :stacked="is_mobile"
          :items="visibleRows()"
          :fields="visible_fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="is_busy"
          select-mode="single"
          selectable
          show-empty
          responsive
          sort-icon-left
          small
          hover
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ utils.formatDate(data.value).datetime }}
        </template>

        <template #cell(userId)="row">
          <div v-if="row.item.statusChangeLog && row.item.statusChangeLog.length > 0">
            {{ row.item.statusChangeLog[0].userEmail }}
          </div>
        </template>

        <template #cell(provider)="row">
          <b-link @click="showItems(row.item)" class="smt17">{{row.item.provider}}</b-link>
        </template>

        <template #cell(lastDeliveryDate)="row">
          <span v-if="row.item.status === 'complete'">
            <b-link @click="showDelivery(row.item)" class="smt17">{{ utils.formatDate(row.item.updatedAt).date }}</b-link>
          </span>
        </template>

        <template #cell(device.shipName)="row">
          <span v-b-tooltip.hover :title="row.item.device ? row.item.device.deviceId : ''">{{ shipName(row.item.device).type }} <br> {{ shipName(row.item.device).val }}</span>
<!--          {{ shipName(row.item.device).type }} <br> {{ shipName(row.item.device).val }}-->

        </template>

        <template #cell(paymentMethod.paymentMethod)="row">
          <span v-if="row.item.paymentMethod?.paymentMethod === 'agreement'">agreement<br>{{row.item.paymentMethod.agreementInfo.title}}</span>
          <span v-else>{{ row.item.paymentMethod?.paymentMethod }}</span>
        </template>

        <template #cell(load)="row">
<!--          <b-row>-->
<!--            <b-col cols="6">-->
<!--              <b-button v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && row.item.device.appTypeId === 11 && row.item.paymentMethod.paymentMethod === 'agreement'" size="sm" variant="warning" @click="loadOrder(row.item)" class="mr-1">Edit</b-button>-->
<!--            </b-col>-->
<!--            <b-col cols="6" class="text-right">-->
<!--              <b-dropdown size="sm" v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && row.item.device.appTypeId === 11 && row.item.paymentMethod.paymentMethod === 'agreement'" variant="warning" text="Status">-->
<!--                <b-dropdown-item @click="approveOrder(row.item, 'pre_order')">Request</b-dropdown-item>-->
<!--                <b-dropdown-item @click="approveOrder(row.item, 'owner_approved')">To pay</b-dropdown-item>-->
<!--                <b-dropdown-item @click="approveOrder(row.item, 'cancelled_by_distirbutor')">Cancel</b-dropdown-item>-->
<!--              </b-dropdown>-->
<!--            </b-col>-->
<!--          </b-row>-->
          <b-button-group>
          <b-button v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && (row.item.device.appTypeId === 11 || row.item.device.appTypeId === 10) && row.item.paymentMethod.paymentMethod === 'agreement'" size="sm" variant="info" @click="loadOrder(row.item)" class="mr-1">Edit</b-button>
          <b-dropdown size="sm" v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && (row.item.device.appTypeId === 11 || row.item.device.appTypeId === 10) && row.item.paymentMethod.paymentMethod === 'agreement'" variant="info" text="Status">
            <b-dropdown-item @click="approveOrder(row.item, 'pre_order')">Request</b-dropdown-item>
            <b-dropdown-item @click="approveOrder(row.item, 'owner_approved')">To pay</b-dropdown-item>
            <b-dropdown-item @click="approveOrder(row.item, 'cancelled_by_distirbutor')">Cancel</b-dropdown-item>
          </b-dropdown>
          </b-button-group>
        </template>

        <template #cell(status)="row">
          <h6><b-badge :variant=getStatus(row.item.status).style>{{ getStatus(row.item.status).name }}</b-badge></h6>
        </template>

        <template #cell(displayId)="row">
          <span v-b-tooltip.hover :title=row.item.orderId>{{ row.item.displayId }}</span>
        </template>

        <template #cell(updatedAt)="row">
          <b-link @click="showUpdates(row.item)" class="smt17">{{ utils.formatDate(row.item.updatedAt).date }}</b-link>
        </template>

        <template #cell(price.price)="row">
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'RUB'">{{ row.item.price.price }} ₽</div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'EUR'">{{ row.item.price.price }} €</div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'USD'">${{ row.item.price.price }}</div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === '' && row.item.paymentMethod.paymentMethod === 'stripe'">{{ row.item.price.price }} €</div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === '' && row.item.paymentMethod.paymentMethod === 'yoomoney'">{{ row.item.price.price }} ₽</div>
        </template>
      </b-table>

    </b-overlay>

  </div>
</template>

<script>
import store from "@/store";
import {CSVParse, excelParser, formatDate, getAppName, getAppType, getStatusByType} from "@/utils/utils";
// import Ship from "@/views/transactions/sidebars/Ship.vue";
// import Payment from "@/views/transactions/sidebars/Payment.vue";
import Items from "@/views/transactions/sidebars/Items.vue";
// import ItemsEdit from "@/views/transactions/sidebars/ItemsEdit.vue";
// import Charts from "@/views/transactions/sidebars/Charts.vue";
// import Price from "@/views/transactions/sidebars/Price.vue";
// import Status from "@/views/transactions/sidebars/Status.vue";
// import Messages from "@/views/transactions/sidebars/Messages.vue";
import Delivery from "@/views/transactions/sidebars/Delivery.vue";
import QRScanner from "@/components/qr/QRScanner.vue";
import QRDetails from "@/components/qr/QRDetails.vue";
import Updates from "@/views/transactions/sidebars/Updates.vue"
// import NewOrder from "@/views/transactions/sidebars/AddOrder.vue";
// import QRWindow from "@/components/qr/QRWindow";
import {eventBus} from "@/main";
import router from "@/router";
import * as utils from "../../utils/utils";
import QRWindow from "@/components/qr/QRWindow.vue";
import {changeOrderStatus} from "@/helpers/offline";

export default {
  name: "Transactions",
  components: {QRWindow, Updates, Delivery, Items},
  data() {
    return {
      windowWidth: window.innerWidth,
      modal_warning: false,
      show_spinner: false,
      qrcode: null,
      source1: 'report.pdf',
      sortBy: 'updatedAt',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      current: {
        status_id: '',
        data: null
      },
      ship: {}
    }
  },
  mounted() {

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    eventBus.$off('refresh-orders-table')
    eventBus.$on('refresh-orders-table', () => {
      this.$refs.orderstable1.refresh();
    })

    eventBus.$off('delayed-request-order-status')
    eventBus.$on('delayed-request-order-status', () => {
      store.dispatch('transactions/getTransactions').then(() => {
      })
    })


    // this.$store.dispatch("proto/getProto")
    //AAA
    // this.$store.dispatch("transactions/getTransactions", this.$store.state.auth.user.displayId);
  },
  computed: {
    is_mobile() {
      // let val = this.windowWidth <= 760 ? true : false
      return this.windowWidth <= 760
    },
    is_busy() {
      return this.$store.state.transactions.transactions.length > 0 && this.$store.state.transactions.transactions[0] === "-1" &&
          this.$store.state.charts.s63.products.all.length > 0 && this.$store.state.proto.installationsECDIS.length > 0
    },
    utils() {
      return utils
    },
    current_status() {
      let item = this.$store.state.transactions.status.find(obj => {
        return obj.id === this.current.status_id
      })

      return item === undefined ? "" : item.status;
    },
    current_history() {
      let item = this.$store.state.transactions.status.find(obj => {
        return obj.id === this.current.status_id
      })

      return item === undefined ? "" : item.history;
    },
    // imported_order() {
    //   return this.$store.state.transactions.imported_order
    // },
    // history_fields() {
    //   return [
    //     {key: 'timeStamp', label: 'Time', tdClass: 'small-black', thClass: 'small-gray'},
    //     {key: 'status', label: 'Status', tdClass: 'small-black', thClass: 'small-gray'},
    //     {key: 'changedBy', label: 'Changed by', tdClass: 'small-black', thClass: 'small-gray'},
    //   ]
    // },
    imported_fields() {
      return [
        {key: '0', label: 'Item', tdClass: 'small-black', thClass: 'small-gray'},
        {key: '1', label: 'Title', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
    visible_fields() {
      return [
        {key: 'displayId', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'createdAt', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'userId', label: 'From', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'device.shipName', label: 'Ship', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'provider', label: 'Items', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'paymentMethod.paymentMethod', label: 'Payment', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price.price', label: 'Price', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'updatedAt', label: 'Updated', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'lastDeliveryDate', label: 'Deliveries', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'load', label: '', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'lastMessageDate', label: 'Messages', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'lastDeliveryDate', label: 'Delivery', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', class: 'truncate_name'},
      ]
    }
  },
  methods: {
    selectShip(name) {
      return this.ships().find(x => x.appState.shipInfo.name == name)
      // console.log(ship, this.ships())
    },
    selectShipByDeviceId(deviceId) {
      return this.ships().find(x => x.appState.deviceId == deviceId)
      // console.log(ship, this.ships())
    },

    getStatus(status) {
      let val = {}
      switch (status) {
        case 'pre_order':
          val = {style: "info", name: "request"}
          break
        case 'owner_approved':
          val = {style: "warning", name: "to pay"}
          break
        case 'new':
          val = {style: "warning", name: "to pay"}
          break
        case 'paid':
        case 'ready_to_publish':
        case 'awaiting_charts"':
        case 'adding_charts"':
          val = {style: "secondary", name: "processing"}
          break
        case 'complete':
          val = {style: "success", name: "complete"}
          break
        case 'error':
          val = {style: "danger", name: "error"}
          break
        case 'cancelled_by_distirbutor':
          val = {style: "danger", name: "cancelled"}
          break
        default:
          val = {style: "light", name: status}
      }

      return val
    },

    ships() {

      // let allList = this.$store.state.manage.installations.at(0)
      // let allList = this.$store.state.proto.installationsECDIS
      let listWithAppstate = this.$store.state.proto.installationsECDIS;
      // if (allList) {
      //
      //
      //   listWithAppstate = allList.filter(function (e) {
      //     return e.appState !== null && e.applicationId === 11
      //     // && e.appState.shipInfo.name !== ''
      //   });


        return listWithAppstate;
    },
    approveOrder(orderItem, status) {
      console.log("off approveOrder")

      this.show_spinner = true
      changeOrderStatus(orderItem.orderId, status).then(resp => {
        this.show_spinner = false
        if (resp)
          store.dispatch('transactions/getTransactions').then(() => {
        })
        // resolve(true)
      })
      // return new Promise(resolve => {
      //   this.$store.dispatch('proto/updateOrderStatus',
      //       {
      //         order_id: orderId,
      //         status: status
      //       }).then(() => {
      //     resolve(1)
      //     this.show_spinner = false
      //
      //     store.dispatch('transactions/getTransactions').then(() => {
      //     })
      //     // window.location.reload();
      //   });
      // })
    },

    loadOrder(orderItem) {
      let orderId = orderItem.orderId
      let orderDate = orderItem.createdAt
      let name = orderItem.device.shipName
      let deviceId = orderItem.device.deviceId
      let appId = orderItem.device.appTypeId
      let status = orderItem.status

      console.log(orderId, orderDate, name, deviceId, appId)


      this.show_spinner = true

      // let order_shipName = this.$store.state.transactions.transactions[0].device.shipName
      // let order_deviceId = this.$store.state.transactions.transactions[0].device.deviceId
      // let order_applycationId = this.$store.state.transactions.transactions[0].device.appTypeId



      let getShip = this.selectShipByDeviceId(deviceId)
      let ship = {
        deviceId: deviceId,
        order_number: orderId,
        order_date: orderDate,
        ecdis_name: name,
        ecdis_imo: "",
        applicationId: appId,
        appState: {
          shipInfo:{
            name: name,
            imo: ""
          }
        }
      }

      console.log(ship)
      if (ship === undefined) {

        this.modal_warning = true
        this.show_spinner = false
        return
      }
      // let ship = this.selectShip(name)


      this.onRowSelected([ship])


      this.ship.order_date = new Date(orderItem.createdAt).toLocaleDateString()

      this.onDone()

      this.$store.dispatch('getLatestOrder', {
        order_id: orderId
      }).then(() => {

        store.dispatch('current/clearCart').then(() => {

          let items = this.$store.state.charts.s63.latest_order.data.items
          let idlist = []
          items.forEach(item => {
            idlist.push(item.id)
          })
          this.$store.dispatch('proto/getAgreementsV2').then(() => {
            store.dispatch('addS63Items', idlist).then(() => {
              this.$store.commit('proto/setRestoredOrder', this.$store.state.proto.order)
              router.push("charts")
            })
          })



          this.$store.dispatch('proto/updateOrder', {
            update: true,
            order_status: utils.getStatusByType(status)
          })

        })
      })

      // let id="NO1A3000"
      // let name="NORMANN"
      // let ship = this.selectShip(name)
      // this.onRowSelected([ship])
      // this.onDone()
      // store.dispatch('addS63Items', [id]).then( () => {
      //   router.push("charts")
      //
      // })


    },
    onDone(from) {

      // alert("done")
      // return

      // if (this.ship.device_id === null) {
      //   this.show_no_ship_warning = true
      //   return
      // }


      this.$store.dispatch('proto/updateOrder',
          {
            device_id: this.ship.device_id,
            order_number: this.ship.order_number,
            order_date: this.ship.order_date,
            ecdis_name: this.ship.ecdis_name,
            ecdis_imo: this.ship.ecdis_imo,
            application_id: this.ship.application_id
          }).then(() => {
        // alert("done")
      })

      // this.$store.dispatch('proto/getAgreement',
      //     {
      //       deviceId: this.ship.device_id,
      //       appType: this.ship.application_id
      //     })

      // this.$store.dispatch('proto/getAgreementsV2')

      this.$store.commit("proto/setSelectShip", true)
      this.$store.dispatch('proto/updateOrder', {step: "creating"})

      // eventBus.$emit('fix-scale')
    },
    onRowSelected(items) {


      if (items.length > 0) {
        this.ship.order_number = items[0].order_number
        this.ship.order_date = items[0].order_date
        this.ship.ecdis_name = items[0].appState.shipInfo.name
        this.ship.ecdis_imo = items[0].appState.shipInfo.imo
        this.ship.device_id = items[0].deviceId
        this.ship.application_id = items[0].applicationId
      } else {
        this.ship.order_number = null
        this.ship.order_date = null
        this.ship.ecdis_name = null
        this.ship.ecdis_imo = null
        this.ship.device_id = null
        this.ship.application_id = null
      }

      // alert(this.ship.device_id)
    },
    formatDate,
    providers(products) {
      let providers = ''
      for (const element of products) {
        providers += element.provider + ", "
      }
      return providers.slice(0, -2)
    },
    shipName(device) {
      let val = 'unknown'
      let type = 'unknown'
      if (device !== null) {
        type = getAppName(device ? device.appTypeId : -1)
        // val = device.shipName ? device.shipName : device.deviceId

        // val = device.shipName ?? 'UNKNOWN'
        val = device?.shipName ?? ''

        // if (device && device.shipName)
        //   val = device.shipName
        // else {
        //   val = "aaaa"
        //   console.log("aa", device)
        // }
        // val = device.shipName ?? device.deviceId
        // val = "aaa"
      }
      return {"type": type, "val": val}
    },
    newOrder() {
      this.$root.$emit('bv::toggle::collapse', 'new-order-sidebar');
      router.push({name: "ships"});
    },
    readQR() {
      this.$root.$emit('bv::toggle::collapse', 'qr-sidebar');
      eventBus.$emit('start-qr-scan')
    },
    backToOrder() {
      router.push("charts")
    },
    showItems(data) {
      this.current.data = data;
      this.$store.dispatch('fickle/getOrder', data.orderId)
      this.$root.$emit('bv::toggle::collapse', 'items-sidebar');
    },
    showMessages(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'messages-sidebar');
    },
    showDelivery(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'delivery-sidebar');
    },
    showPrice(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'price-sidebar');
    },
    showStatus(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
    showUpdates(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'updates-sidebar');
    },
    showStatusRequest(data) {
      // this.current.status_id = data.id;
      this.current.data = this.$store.state.proto.transactions.at(0)
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
    showShip(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'ship-sidebar');
    },
    showAgreement(data) {
      console.log(data.paymentMethod)
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'payment-sidebar');
    },

    visibleRows1() {
      let orders = this.$store.state.transactions.transactions;



    },

    visibleRows() {
      let orders = this.$store.state.transactions.transactions;
      // let orders = this.$store.state.proto.transactions;


      //     [
      //     {a1:"13994", a2:"2023-06-06 00:21", a3:"ANADYR (Ellcom Co.Ltd)", a4:"Primar, Navicom", a5:"agreement", a6:"359.3 ₽", a7:"completed"},
      //     {a1:"14022", a2:"2023-06-06 00:21", a3:"NAVIS 7 (Ellcom Co.Ltd)", a4:"Primar", a5:"agreement", a6:"359.3 ₽", a7:"completed"}
      // ];
      return orders;
    },

    // uploadFile() {
    //   let input = document.createElement('input');
    //   input.type = 'file';
    //   input.onchange = e => {
    //     // getting a hold of the file reference
    //     let file = e.target.files[0];
    //     console.log(file.name)
    //
    //     if (!file.name.endsWith(".csv"))
    //       return
    //
    //     // setting up the reader
    //     let reader = new FileReader();
    //     reader.readAsText(file, 'UTF-8');
    //
    //     // here we tell the reader what to do when it's done reading...
    //     let content;
    //     reader.onload = readerEvent => {
    //       content = readerEvent.target.result; // this is the content!
    //       console.log(content);
    //
    //       let CSVarray = CSVParse(content);
    //       console.log(CSVarray)
    //       this.$store.dispatch("transactions/importOrder", CSVarray);
    //       this.$root.$emit('bv::toggle::collapse', 'imported-sidebar');
    //     }
    //   }
    //   input.click();
    // },

    // uploadFile() {
    //   console.log("aaaaaaaaa")
    //   excelParser()
    // },
  },
}
</script>

<style>
.position-absolute {
  position: fixed !important;
}
</style>


<style scoped>
.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>