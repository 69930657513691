import { render, staticRenderFns } from "./ZonesSettings.vue?vue&type=template&id=8da5feda&scoped=true&"
import script from "./ZonesSettings.vue?vue&type=script&lang=js&"
export * from "./ZonesSettings.vue?vue&type=script&lang=js&"
import style0 from "./ZonesSettings.vue?vue&type=style&index=0&id=8da5feda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8da5feda",
  null
  
)

export default component.exports