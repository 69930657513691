<template>

  <div>
    <b-container>
      <b-row>
        <b-col>
          <h4 class="mb-4">Log In To Your Account</h4>
          <p v-if="!IS_NP" class="small-gray">Use the email, password and nickname from the {{ app_name() }} application or register a new account for
            ECDIS. You can reset your password in application if need. For all questions please contact us:
            <a :href="app_email_link()"> {{app_email()}}</a>.</p>
          <p v-else class="small-gray">Use the email, password and nickname from the {{ app_name() }} application. You can reset your password in application if need. For all questions please contact us:
            <a :href="app_email_link()"> {{app_email()}}</a>.</p>

          <b-form @submit="handleLogin">
            <b-container fluid>
              <b-form-row class="mb-2">
                <b-form-input type="email" id="email" v-model="login.username" placeholder="email"
                              required></b-form-input>
              </b-form-row>
              <b-form-row class="mb-2">
                <b-form-input type="password" id="password" v-model="login.password" placeholder="password"
                              required></b-form-input>
              </b-form-row>
<!--              <b-form-row class="mb-2">-->
<!--                <b-form-input type="text" id="nickname" v-model="login.nickname" placeholder="nickname"-->
<!--                              required></b-form-input>-->
<!--              </b-form-row>-->

              <b-form-row>
                <b-col>
                <b-button size="sm" type="submit" name="login" variant="outline-info">Login</b-button>&nbsp;
                <b-button size="sm" v-b-toggle.collapse-registration name="show-registration" variant="outline-info">Registration</b-button>
                </b-col>
<!--                <b-button size="sm" type="restore" name="restore" variant="outline-info">Restore password</b-button>&nbsp;-->
              </b-form-row>
            </b-container>
          </b-form>

          <b-alert v-if="is_registered" variant="success" show class="mt-3">
            Registration successful. Thank you for registering. Please check your email for a password.
          </b-alert>

          <b-alert v-if="is_wrong_registration" variant="warning" show class="mt-3">
            Registration is not successful. Probably the email is already registered.
          </b-alert>

          <b-collapse size="sm" id="collapse-registration" class="mt-2">
            <b-card>
              <b-form @submit="handleRegistration">
                <b-form-group
                    label="Email:"
                    label-for="email"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                  <b-form-input type="email" id="r_email" v-model="registration.username"></b-form-input>
<!--                  <b-form-input type="text" id="r_text" v-model="registration.nickname"></b-form-input>-->
                  <b-button class="mt-2" size="sm" type="submit" name="registration" variant="outline-info">Submit</b-button>
                </b-form-group>
              </b-form>
            </b-card>
          </b-collapse>


          <b-alert v-if="!IS_NP" show class="mt-3">
            <p><b>How to get started</b></p>
<!--            <p>To purchase ENCs and Nautical Publications on WEB portal you need to be registered ether via mobile mKart-->
<!--              or directly on site.</p>            -->
            <p>To purchase ENCs and Nautical Publications on WEB portal you need to be registered ether via mobile {{ app_name() }}.</p>
            <p>Submit please your valid e-mail and get password on it. Enter password and registration is done.</p>
          </b-alert>

          <b-alert v-if="!IS_NP" show class="mt-3">
            <p><b>How to purchase ENCs</b></p>
            <p>Enter your credentials and select your activated mobile device or ECDIS account to get access to
              electronic catalogue.</p>
            <p>You may purchase ready Maps folios like Norway or Estonia, US maps are free. In case of PRIMAR, you have
              to pick up desired ENCs from the WEB map or via Maps Booster functions and proceed with payments. Orders
              history and payments logs are preserved.</p>
            <p>System automatically prevents you from maps double purchase and can help with maps selection by the rout
              which can be calculated automatically and corrected by graphic editor.</p>
          </b-alert>

        </b-col>

        <b-col v-if="!IS_GETENC()">
          <div v-if="!IS_NP()">
            <h4 class="mb-4">News</h4>

            <p class="date-header">7 Dec 2022 | mKart WEB Portal release</p>

            <p>Dear mKart Customer.</p>
            <p>We glad to inform you that mKartWEB.com portal is finally released and available for your service via your
              e-mail and password registration.</p>
            <p>On mKart web.com you may select and buy electronic maps from PRIMAR and commercial products from few
              national HOs. You can see your ship’s latest position and calculate the route to new destination in the
              automatic mode. Required ENCs for your voyage will be selected automatically and verified against your
              current portfolio.</p>
            <img src="https://mkartweb.com/pics/01_01.jpg" width="500">
            <br><br>
            <p>You may purchase selected electronic maps directly on site by credit card and after it is done data will be
              uploaded on your device on-line automatically with providing notifications both via e-mail and in the mobile
              App.</p>
            <p>For using mKartWEB.com we recommend upgrade you mobile App version to the latest and get access to improved
              functionality and performance. The letter about new features and functions will be submitted soon.</p>
            <img src="https://mkartweb.com/pics/01_02.jpg" width="500">
            <br><br>
          </div>

        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import router from "@/router";
import {eventBus} from "@/main";
import {myAxios} from "@/services/myAxios";
import {file} from "paths.macro";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import {lodash} from "lodash/seq";
import store from "@/store";

export default {
  props:['xxx'],
  data() {
    return {
      prevRoute: null,
      login: {
        username: "",
        password: "",
        nickname: "",
      },
      registration: {
        username: "",
        password: "",
        nickname: "",
      },
      is_registered: false,
      is_wrong_registration: false,
      is_mconnect: false,
      mconnect_token: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = router.history._startLocation;
    })

  },
  created() {
    this.isMobile();
  },
  mounted() {

    store.commit("fickle/setUserInstallationsReady", false)
    store.commit("fickle/setUserDataReady", false)
    store.commit("fickle/setENCDataReady", false)

    this.test()

    // this.$store.commit('devices/setSelectedDevice', null);
    // this.$store.commit('devices/setSelectedDeviceId', null);

    this.getName()

    eventBus.$on('is-registered', (flag) => {
      console.log("is-registered " + flag)

      if (flag) {
        this.is_registered = true;
        this.is_wrong_registration = false;
      } else {
        this.is_wrong_registration = true;
        this.is_registered = false;
      }
    })
  },
  methods: {
    IS_GETENC() {
      return IS_GETENC
    },
    test() {
      // let _ = require('lodash');
      // alert('test')
      // let array = [1, 2, 3];
      // alert(array)
      // _.fill(array, 'a');
      // alert(array)
    },
    app_name() {
      if (!this.IS_NP())
        return 'mKart/Vega/etc.'
      else
        return 'Njord Pilot'
    },
    app_email_link() {
      if (!this.IS_NP())
        return "mailto:info@mkart.fi"
      else
        return "mailto:support@njordpilot.com"
    },
    app_email() {
      if (!this.IS_NP())
        return 'info@mkart.fi'
      else
        return 'support@njordpilot.com'
    },
    IS_NP() {
      return IS_NP
    },
    getName() {
      let urlParams = new URLSearchParams(window.location.search)
      let urlPath1 = new URLSearchParams(window.location.pathname)
      let urlPath2 = window.location.pathname
      console.log("dddd1", urlParams)
      console.log("dddd2", urlPath1)
      console.log("dddd3", urlPath2)
      let param = urlParams.get("mconnect")
      let param1 = urlParams.get("orders")
      console.log("dddd4", param1)

      if (param) {
        this.is_mconnect = true;
        this.mconnect_token = param;

        this.$store.dispatch("auth/loginWithMconnect", param)
      }

      if (param1) {
        // this.is_mconnect = true;
        // this.mconnect_token = param;

        this.$store.dispatch("auth/loginWithMconnect", param)
      }



    },
    handleLogin(event) {
      event.preventDefault();
      if (event.submitter.name === "login" && this.login.username && this.login.password) {
        this.$store.dispatch("auth/login", {
          username: this.login.username,
          password: this.login.password,
          prevRoute: this.prevRoute
        });
      } else {
        // TODO
      }
    },
    handleRegistration(event) {
      console.log(event.submitter.name)
      event.preventDefault();
      this.$root.$emit('bv::toggle::collapse', 'collapse-registration')
      if (event.submitter.name === "registration" && this.registration.username) {
        this.$store.dispatch("auth/register", {
          email: this.registration.username,
          password: '',
          userType: 'user',
          applicationTypeId: !IS_NP ? 0 : 1
        });
      } else {
        // TODO
      }
    },
    isMobile() {
      if (window.outerWidth <= 760 && window.matchMedia("(orientation: portrait)").matches && !this.IS_GETENC()) {
        router.push({path: 'mobile'})
        return true
      } else {
        return false
      }
    },
  },
};
</script>

<style scoped>

p.date-header {
  position: relative;
  width: 70%;
  font-size: 1.0em;
  font-weight: normal;
  padding: 1px 10px 1px 25px;
  margin: 0 10px 25px 0;
  color: #ffffff;
  background-color: #17a2b8;
  text-shadow: 0px 1px 2px #bbb;
  -webkit-box-shadow: 0px 2px 4px #888;
  -moz-box-shadow: 0px 2px 4px #888;
  box-shadow: 0px 2px 4px #888;
}

p:before, p:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}


p.date-header:after {
  left: 0px;
  top: 100%;
  border-width: 5px 5px;
  border-style: solid;
  border-color: #666 #666 transparent transparent;
}
</style>