import * as fxparser from "fast-xml-parser";
import * as xml_js from "xml-js";
import store from "@/store";

let _ = require('lodash');

export class TokenParser {
    cellName = ''
    UUID = 0
    tokenXML = null
    // fcXML = null
    attributeUUID = 0
    attributeCodes = []
    simpleAttributes = []
    complexAttributes = []
    points = []
    featureTypes = []
    tokensTree = []
    features = []
    objByKey = undefined
    isKeyFound = false
    descriptor = []
    descriptorNames = []


    constructor() {
        // this.cellName = cellName
    }

    clear() {
        this.tokenXML = null
        this.attributeUUID = 0
        this.attributeCodes = []
        this.simpleAttributes = []
        this.complexAttributes = []
        this.points = []
        this.featureTypes = []
        this.tokensTree = []
        this.features = []
        this.objByKey = undefined
        this.isKeyFound = false
    }


    removeUniqueId2(token) {
        let tokenKeys = Object.keys(token)
        tokenKeys.forEach(item => {
            if (item !== 'UNIQUE_KEY' && item !== 'spatialAssociation' && item !== '_text') {
                if (item !== 'FASC' && item !== 'INAS')
                    delete token[item].UNIQUE_KEY

                if (Object.keys(token[item]).length > 0) {
                    this.removeUniqueId2(token[item])
                }
            }
        })
    }

    removeUniqueId(tokens) {
        tokens.forEach(token => {
            delete token.UNIQUE_KEY
            if (Object.keys(token).length > 0) {
                this.removeUniqueId2(token)
            }
        })
    }

    addUniqueId2(token) {
        let tokenKeys = Object.keys(token)
        tokenKeys.forEach(item => {
            if (item !== 'UNIQUE_KEY' && item !== 'spatialAssociation' && item !== '_text') {
                if (item !== 'FASC' && item !== 'INAS')
                    token[item].UNIQUE_KEY = String(this.UUID++);

                if (Object.keys(token[item]).length > 0) {
                    this.addUniqueId2(token[item])
                }
            }
        })
    }

    addUniqueId(tokens) {
        tokens.forEach(token => {
            token.UNIQUE_KEY = String(this.UUID++);
            if (Object.keys(token).length > 0) {
                this.addUniqueId2(token)
            }
        })


        // console.log("jj1", jsonData)

        // const json_getAllKeys = data => (
        //     data.reduce((keys, jsonData) => (
        //         keys.concat(Object.keys(jsonData).filter(key => (
        //             keys.indexOf(key) === -1))
        //         )
        //     ), [])
        // )

        // console.log("jj", json_getAllKeys)

        // var keys = [];
        // for(var i = 0;i<input.document.people.length;i++)
        // {
        //     Object.keys(input.document.people[i]).forEach(function(key){
        //         if(keys.indexOf(key) == -1)
        //         {
        //             keys.push(key);
        //         }
        //     });
        // }
        // console.log(keys);

        // let jList = Object.keys(jsonData)
        // console.log("jj1", jList)

        // for (let key in jsonData) {
        //     if (jsonData[key]) {
        //         this.addUniqueId(jsonData)
        //         console.log("jj2", jsonData[key])
        //         // jsonData[key].UNIQUE_KEY = UUID++;
        //     }
        //
        // }
        // return tokens;
    }

    prepareDescriptor() {
        // console.log("_de")

        let simpleAttributes = _.get(store.state.fickle.tokens.fcXML, "S100FC:S100_FC_FeatureCatalogue.S100FC:S100_FC_SimpleAttributes.S100FC:S100_FC_SimpleAttribute")
        let pattern = _.find(simpleAttributes, {"S100FC:code": 'descriptor'})['S100FC:constraints']['S100FD:textPattern']
        // console.log("_de", pattern)
        let rules = _.words(pattern, /[^{}]+/g)
        let newRules = []
        rules.forEach((item, i) => {
            item = _.trim(item)
            if (item !== '') {

                let pair = _.split(item, ":")
                // console.log("_de", pair[0], pair[1])
                let complex = (pair[1].startsWith("'")) ? false : true
                if (pair[0].startsWith("(")) {
                    pair[0] = pair[0].replace('(', '')
                    pair[0] = pair[0].replace(')', '')
                    let variants = _.split(pair[0], "|")
                    variants.forEach(item => {
                        newRules.push({key: item, value: pair[1], complex: complex, index: i})
                    })
                } else {
                    newRules.push({key: pair[0], value: pair[1], complex: complex, index: i})
                }


            }
        })
        // console.log("_de", newRules)
        store.commit("fickle/setTokensRules", newRules)


    }

    async loadFC() {
        return new Promise(resolve => {

            const options = {ignoreAttributes: false};
            const parser = new fxparser.XMLParser(options)

            // const delay = (delayInms) => {
            //     return new Promise(resolve => setTimeout(resolve, delayInms));
            // };

            fetch('S-100_FC_Tokens_v4.xml')
                .then(response => response.blob())
                .then(blob => {
                    let reader = new FileReader();
                    reader.readAsText(blob, 'UTF-8');

                    let fcContent;
                    reader.onload = readerEvent => {
                        fcContent = readerEvent.target.result;
                        let fcXML = parser.parse(fcContent)
                        store.commit("fickle/setTokensFC", fcXML)
                        // console.log("fc22221")

                        // let datetime1 = new Date().getTime();
                        // let datetime2 = datetime1 + 1000;// 4 second delay
                        //
                        // while(datetime1<datetime2) {
                        //     datetime1 = new Date().getTime();
                        // }

                        // console.log("fc22223")
                        this.prepareDescriptor()
                        resolve()
                    }
                })
        })
    }

    // loadFC() {
    //     const options = { ignoreAttributes: false};
    //     const parser = new fxparser.XMLParser(options)
    //
    //     fetch('S-100_FC_Tokens_v1.xml')
    //         .then(response => response.blob())
    //         .then(blob => {
    //             let reader = new FileReader();
    //             reader.readAsText(blob, 'UTF-8');
    //
    //             let fcContent;
    //             reader.onload = readerEvent => {
    //                 fcContent = readerEvent.target.result;
    //                 let fcXML = parser.parse(fcContent)
    //
    //                 store.commit("fickle/setTokensFC", fcXML)
    //             }
    //         })
    // }

    async init(cellName, tokensFileName) {

        return new Promise(resolve => {

            this.clear()

            this.cellName = cellName

            // const options = {
            //     ignoreAttributes: false,
            // };
            const options2 = {
                compact: true
            };
            // const parser = new fxparser.XMLParser(options)

            // if (this.fcXML === null) {
            //     fetch('S-100_FC_Tokens_v1.xml')
            //         .then(response => response.blob())
            //         .then(blob => {
            //             let reader = new FileReader();
            //             reader.readAsText(blob, 'UTF-8');
            //
            //             let fcContent;
            //             reader.onload = readerEvent => {
            //                 fcContent = readerEvent.target.result;
            //
            //                 this.fcXML = parser.parse(fcContent)
            //             }
            //         })
            // }


            fetch(tokensFileName)
                .then(response => response.blob())
                .then(blob => {
                    let reader = new FileReader();
                    reader.readAsText(blob, 'UTF-8');

                    let tokensContent;
                    reader.onload = readerEvent => {
                        tokensContent = readerEvent.target.result;

                        this.tokenXML = xml_js.xml2js(tokensContent, options2)
                        let tok = _.get(this.tokenXML, "Dataset.FeatureObjects.FeatureObject")
                        this.addUniqueId(tok)
                        // console.log("uu42", this.tokenXML)


                        // console.log("fc12")
                        this.startParse()
                        // console.log("fc24")
                        resolve()


                    }
                })


            // setTimeout(() => {
            //     this.startParse()
            // }, 2000)

        })

    }

    checkForDescriptor(feature) {
        let isComplexAttr = feature.code === undefined
        let featureCode = !isComplexAttr ? feature.code : feature.attr.code


        store.state.fickle.tokens.rules.forEach(item => {
            // console.log("ii24", featureCode)
            //
            // console.log("ii24_1", featureCode)
            // console.log("ii24_2", feature.attr)
            // console.log("ii24_3", feature.childs)

            if (item.key === featureCode && !item.complex) {
                // console.log("__de", item.value)
                // console.log("__de", _.replace(item.value, /'/g, ''))
                this.descriptor.push({val: (item.value).replace(/'/g, ''), index: item.index})
            }

            if (item.key === featureCode && item.complex && !isComplexAttr) {
                let val = _.find(feature.attributes, {code: item.value})
                if (val)
                    this.descriptor.push({val: val.val + ";", index: item.index})
            }

            // if (isComplexAttr)
            //     console.log("dddd", featureCode)

            if (item.key === featureCode && item.complex && isComplexAttr) {

                // console.log("dddd", featureCode)

                let val = _.find(feature.childs, {code: item.value})
                if (val)
                    this.descriptorNames.push({val: val.val, index: item.index})
            }

        })

    }

    restoreTree(cellName) {
        // console.log("dddd", cellName)
        // console.log("dddd", this.tokensTree = _.find(store.state.fickle.all_tokens, {cell: cellName}))
        this.tokensTree = _.find(store.state.fickle.all_tokens, {cell: cellName})['tokens']
    }

    makeDescriptor() {

        this.tokensTree.forEach(token => {



        this.descriptor = []
        this.descriptorNames = []

        // if (this.tokensTree[0] === 'Pile')
        //     return

        // console.log("dddd1", this.tokensTree)
        // console.log("dddd2", this.tokensTree[0])

            token.components.forEach(component => {
            this.checkForDescriptor(component)
        })

        // check AToN
        this.checkForDescriptor(token)

        // check Name
            token.complex_attributes.forEach(attribute => {
            this.checkForDescriptor(attribute)
        })

        let currentDescriptor = _.find(token.attributes, {code: 'descriptor'})

        // console.log("jj47", this.tokensTree[0])
        // console.log("jj47", currentDescriptor)

        let newDescriptor = ''
        this.descriptor.forEach(item => {
            newDescriptor += item.val + ' '
        })

        this.descriptorNames.forEach((item, i) => {
            if (i === 0)
                newDescriptor += "names: "
            newDescriptor += item.val
            if (i < this.descriptorNames.length - 1)
                newDescriptor += ', '
        })

        if (currentDescriptor)
            currentDescriptor.val = newDescriptor

        // console.log("desc", newDescriptor)

        })

    }

    autoEdit(type) {
        alert(type)
        if (type === "1")
            store.commit("fickle/autoEdit1")
    }

    startParse() {
        this.UUID = 0;
        this.attributeUUID = 0;

        this.simpleAttributes = _.get(store.state.fickle.tokens.fcXML, "S100FC:S100_FC_FeatureCatalogue.S100FC:S100_FC_SimpleAttributes.S100FC:S100_FC_SimpleAttribute")
        this.complexAttributes = _.get(store.state.fickle.tokens.fcXML, "S100FC:S100_FC_FeatureCatalogue.S100FC:S100_FC_ComplexAttributes.S100FC:S100_FC_ComplexAttribute")
        this.featureTypes = _.get(store.state.fickle.tokens.fcXML, "S100FC:S100_FC_FeatureCatalogue.S100FC:S100_FC_FeatureTypes.S100FC:S100_FC_FeatureType")

        this.readFeatures()

        this.features.forEach(feature => {
            this.tokensTree.forEach((token, i) => {
                token.links.forEach(link => {
                    if (link === feature.id) {
                        this.tokensTree[i]['components'].push(feature)
                    }
                })
            })
        })

        // console.log("pppp", this.tokensTree[0])

        // this.tokensTree[0].components.forEach(component => {
        //     this.checkForDescriptor(component)
        // })
        //
        // // check AToN
        // this.checkForDescriptor(this.tokensTree[0])
        //
        // // check Name
        // this.tokensTree[0].complex_attributes.forEach(attribute => {
        //     this.checkForDescriptor(attribute)
        // })


        // let currentDescriptor = _.find(this.tokensTree[0].attributes, {code:'descriptor'})
        // let newDescriptor = ''
        // this.descriptor.forEach(item => {
        //     newDescriptor += item.val + ' '
        // })
        //
        // this.descriptorNames.forEach((item, i) => {
        //     if (i === 0)
        //         newDescriptor += "names: "
        //     newDescriptor += item.val
        //     if (i < this.descriptorNames.length - 1)
        //         newDescriptor += ', '
        // })
        //
        // currentDescriptor.val = newDescriptor

        this.makeDescriptor()
        store.commit("fickle/setTokens", {cell: this.cellName, tokens: this.tokensTree})

        this.readPoints()
    }

    readPoints() {
        let pointsArray = []
        let points = _.get(this.tokenXML, "Dataset.Points.Point");
        points.forEach((item, i) => {
            let lat = _.get(item, "Lat._text");
            let lon = _.get(item, "Lon._text");
            let id = _.get(item, "_attributes.Id");

            pointsArray.push({'lat': lat, 'lon': lon, 'id': id})
        })

        store.commit("fickle/setTokensPoints", {points: pointsArray, cellName: this.cellName})
    }

    readFeatures() {

        console.log(this.tokenXML)
        let features = _.get(this.tokenXML, "Dataset.FeatureObjects.FeatureObject");
        // console.log("ab", features)

        features.forEach((item, i) => {

            // console.log("uu8", item)

            let tokenCode = _.get(item, "ObjectId.acronym._text");
            let tokenRCID = _.get(item, "ObjectId.rcid._text")
            let tokenKey = _.get(item, "ObjectId.UNIQUE_KEY")
            let featureAssociations = _.get(item, "FASC")
            let tokenName = _.find(this.featureTypes, {"S100FC:code": tokenCode})['S100FC:name']

            // console.log("uu8___", item)

            let featureAssociationsValues = _.values(featureAssociations)

            let links = []

            // console.log("uu4", featureAssociations)
            featureAssociationsValues.forEach(item => {
                // console.log("uu8", item)
                links.push(item["ObjectId"]["rcid"]["_text"])
                // console.log("uu8___", item)
            })


            let feature
            let isToken = !this.isEmpty(featureAssociations)
            // console.log("ab", tokenName)
            // console.log("ab", "--------------------------")

            let spatial = ''
            if (isToken && tokenName !== 'Pile') {
                if (item.spatialAssociation) {
                    spatial = item.spatialAssociation.rrid._text
                    // console.log("uuuu", tokenName, item, item.spatialAssociation, spatial )
                }

                feature = {name: tokenName, code: tokenCode, id: tokenRCID, key: tokenKey, components: [], attributes: [], complex_attributes: [], links: links, spatial: spatial, changed: false}

                feature = this.readAttributes(item, feature)
                this.tokensTree.push(feature)

            } else {
                feature = {name: tokenName, code: tokenCode, id: tokenRCID, key: tokenKey, attributes: [], complex_attributes: [], spatial: ''};
                feature = this.readAttributes(item, feature)
                this.features.push(feature)
            }

        })
    }

    isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    readSubAttributes(items) {

        // console.log("bb_sub", items)
        // items.forEach(record => {
        //     console.log("bb_sub", record)
        // })
    }

    readAttributes(item, feature) {
        let itemRecords = _.toPairsIn(item)

        itemRecords.forEach(record => {
            // console.log("bb1", record)
            if (Array.isArray(record[1])) {
                record[1].forEach(rec => {
                    let newRec = [record[0], rec]
                    // console.log("bb2", rec)
                    // console.log("bb2", newRec)
                    feature = this.readAttributes2(newRec, feature)
                })
            } else
                feature = this.readAttributes2(record, feature)
        })

        return feature
    }

    readAttributes2(record, feature) {
        // let isComplexAttribute = false
        // let itemRecords = _.toPairsIn(item)
        // console.log("ab", itemRecords)

        // itemRecords.forEach(record => {
        let recName = record[0]
        let val = record[1]['_text']
        let key = record[1]['UNIQUE_KEY']

        if (val === undefined)
            val = ''

        if (recName !== 'INAS' && recName !== 'FASC' && recName !== 'ObjectId' && recName !== 'UNIQUE_KEY') {
            // console.log("ab1", recName)

            if (recName === 'spatialAssociation' || recName === 'maskedSpatial') {
                if (recName === 'spatialAssociation') {
                    let spatialId = record[1]['rrid']
                    feature.spatial = spatialId
                }
            } else {


                let attrEntry = _.find(this.simpleAttributes, {"S100FC:code": recName})
                let isComplexAttribute = !attrEntry

                // console.log("aaaa", recName)

                if (isComplexAttribute) {
                    // console.log("ab2", recName)
                    attrEntry = _.find(this.complexAttributes, {"S100FC:code": recName})

                    // console.log("aaaa", attrEntry)

                    let attrName = attrEntry["S100FC:name"]
                    let attrCode = attrEntry["S100FC:code"]
                    let attrType = attrEntry["S100FC:valueType"]
                    let attrAvailableValues = attrEntry["S100FC:listedValues"]

                    let attr = {name: attrName, code: attrCode, val: undefined, index: 0, parent: 0, complex: isComplexAttribute, type: attrType, availableValues: attrAvailableValues, key: key, UUID: this.attributeUUID++}

                    let childs = []
                    // if (recName === 'featureName') {
                    //     attrEntry = _.find(this.complexAttributes, {"S100FC:code": recName})
                    //     console.log("complex0", recName)
                    //     console.log("complex1", attrEntry)
                    //     console.log("complex2", record)
                    // this.readSubAttributes(record[1])

                    let itemRecords = _.toPairsIn(record[1])
                    // console.log("aaaa", itemRecords)
                    itemRecords.forEach(item => {

                        if (item[0] !== 'UNIQUE_KEY') {

                            // console.log("bb_", item)
                            // console.log("bb_", item[0])
                            // console.log("bb_", item[1]['_text'])

                            let key = item[1]['UNIQUE_KEY']

                            let childAttrEntry = _.find(this.simpleAttributes, {"S100FC:code": item[0]})
                            if (!childAttrEntry)
                                childAttrEntry = _.find(this.complexAttributes, {"S100FC:code": item[0]})

                            let val = item[1]['_text']
                            // console.log("aaaa2", item[0], item[1]['_text'])

                            // console.log("aaaa", childAttrEntry)

                            let attrName = childAttrEntry["S100FC:name"]
                            let attrCode = childAttrEntry["S100FC:code"]
                            let attrType = childAttrEntry["S100FC:valueType"]
                            let attrAvailableValues = childAttrEntry["S100FC:listedValues"]

                            let attrVal = ''
                            if (val === '' || val === undefined) {
                                attrVal = '<empty>'
                            } else if (attrType === 'integer') {
                                attrVal = val
                            } else if (attrType === 'real') {
                                attrVal = val
                            } else if (attrType === 'boolean') {
                                attrVal = val
                            } else if (attrType === 'text') {
                                attrVal = val
                            } else if (attrType === 'enumeration') {
                                let attrValues = childAttrEntry["S100FC:listedValues"]["S100FC:listedValue"]

                                // console.log("ee4", attrValues, val)

                                // if (val !== '') {
                                let attr = _.find(attrValues, {"S100FC:code": Number(val)})
                                // console.log("ee42", attr, val)
                                attrVal = attr['S100FC:label']
                                // }
                            } else {
                                // alert("Error in attrType")
                            }


                            let attr = {name: attrName, code: attrCode, val: attrVal, index: 0, parent: 0, complex: false, type: attrType, availableValues: attrAvailableValues, key: key, UUID: this.attributeUUID++}
                            childs.push(attr)
                        }
                    })

                    //
                    // }

                    feature.complex_attributes.push({attr, childs: childs})

                    // return feature
                } else {

                    // console.log("complex", attrEntry)

                    let attrName = attrEntry["S100FC:name"]
                    let attrCode = attrEntry["S100FC:code"]
                    let attrType = attrEntry["S100FC:valueType"]
                    let attrAvailableValues = attrEntry["S100FC:listedValues"]

                    let attrVal = ''
                    if (val === '' || val === undefined) {
                        attrVal = '<empty>'
                    } else if (attrType === 'integer') {
                        attrVal = val
                    } else if (attrType === 'real') {
                        attrVal = val
                    } else if (attrType === 'boolean') {
                        attrVal = val
                    } else if (attrType === 'text') {
                        attrVal = val
                    } else if (attrType === 'enumeration') {
                        let attrValues = attrEntry["S100FC:listedValues"]["S100FC:listedValue"]

                        // console.log("ee4", attrValues, val)

                        // if (val !== '') {
                        let attr = _.find(attrValues, {"S100FC:code": Number(val)})
                        // console.log("ee42", attr, val)
                        attrVal = attr['S100FC:label']
                        // }
                    } else {
                        // alert("Error in attrType")
                    }

                    // if (isComplexAttribute) {
                    //     console.log("complex", attrName, attrCode, attrVal)
                    // }

                    let attribute = {name: attrName, code: attrCode, val: attrVal, index: 0, parent: 0, complex: isComplexAttribute, type: attrType, availableValues: attrAvailableValues, key: key, UUID: this.attributeUUID++}
                    feature.attributes.push(attribute)
                }
            }

        }
        // })

        return feature
    }

    readAttributes1(attributes, is_token, index, featureAssociationID) {
        let attrs = []
        let complexAttrs = []

        attributes["NATC"].forEach((item, i) => {
            let val = attributes["ATVL"][i]["_text"]
            let parent = attributes["PAIX"][i]["_text"]
            let attr = this.readAttribute(item["_text"], val, Number(parent), i + 1)

            if (attr.parent > 0) {
                let parentIndex = -1
                complexAttrs.forEach((item, i) => {
                    if (item.attr.index === attr.parent) {
                        parentIndex = i
                    }
                })
                complexAttrs[parentIndex].childs.push(attr)
            }

            if (attr.complex)
                complexAttrs.push({attr, childs: []})
            else if (attr.parent === 0)
                attrs.push(attr)
        })


        if (!is_token) {
            _.set(this.features, [index, 'attributes'], attrs)
            _.set(this.features, [index, 'complex_attributes'], complexAttrs)
        } else {
            let tokenIndex = _.findIndex(this.tokensTree, {'id': featureAssociationID});
            _.set(this.tokensTree, [tokenIndex, 'attributes'], attrs)
            _.set(this.tokensTree, [tokenIndex, 'complex_attributes'], complexAttrs)
        }
    }

    readAttribute1(id, val, parent, index) {
        let isComplexAttribute = false

        if (val === undefined)
            val = ''

        let attribute = _.find(this.attributeCodes, {id}).val
        let attrEntry = _.find(this.simpleAttributes, {"S100FC:code": attribute})

        if (!attrEntry) {
            isComplexAttribute = true
            attrEntry = _.find(this.complexAttributes, {"S100FC:code": attribute})
        }

        let attrName = attrEntry["S100FC:name"]
        let attrCode = attrEntry["S100FC:code"]
        let attrType = attrEntry["S100FC:valueType"]
        let attrAvailableValues = attrEntry["S100FC:listedValues"]

        let attrVal = '<empty>'
        if (attrType === 'integer') {
            attrVal = val
        } else if (attrType === 'real') {
            attrVal = val
        } else if (attrType === 'boolean') {
            attrVal = val
        } else if (attrType === 'text') {
            attrVal = val
        } else if (attrType === 'enumeration') {
            let attrValues = attrEntry["S100FC:listedValues"]["S100FC:listedValue"]

            if (val !== '') {
                let attr = _.find(attrValues, {"S100FC:code": Number(val)})
                attrVal = attr['S100FC:label']
            }
        } else {
            // alert("Error in attrType")
        }

        return {name: attrName, code: attrCode, val: attrVal, index: index, parent: parent, complex: isComplexAttribute, type: attrType, availableValues: attrAvailableValues, UUID: this.attributeUUID++}
    }

    getAvailableAttrubutes(featureCode) {
        let items = []
        let featureEntry = _.find(this.featureTypes, {"S100FC:code": featureCode})

        let indexOfComponent = _.findIndex(store.state.fickle.tokens.components, {'code': featureCode});

        featureEntry['S100FC:attributeBinding'].forEach(item => {
            let ref = item['S100FC:attribute']['@_ref']
            let attrEntry = _.find(this.simpleAttributes, {"S100FC:code": ref})
            if (!attrEntry)
                attrEntry = _.find(this.complexAttributes, {"S100FC:code": ref})

            let attrIndex = _.findIndex(store.state.fickle.tokens.components[indexOfComponent].attributes, {'code': attrEntry['S100FC:code']})

            if (attrIndex === -1)
                items.push({value: attrEntry['S100FC:code'], text: attrEntry['S100FC:name']})
        })
        return items
    }

    saveXML() {
        // const options = {
        //     format: true,
        //     ignoreAttributes : false,
        //     suppressUnpairedNode: true,
        //     preserveOrder: true
        // };
        //
        // const builder = new fxparser.XMLBuilder(options)
        // let xml = builder.build(this.tokenXML);

        // console.log(this.tokenXML1)

        let options = {fullTagEmptyElement: false, compact: true, spaces: '\t'};

        let cloneTokenXML = JSON.parse(JSON.stringify(this.tokenXML))
        let tok = _.get(cloneTokenXML, "Dataset.FeatureObjects.FeatureObject")
        this.removeUniqueId(tok)

        let xml = xml_js.js2xml(cloneTokenXML, options)

        return xml
    }

    getPath(obj, givenKey, givenValue) {
        for (var key in obj) {
            if (obj[key] && typeof obj[key] === "object") {
                var result = this.getPath(obj[key], givenValue, givenKey);
                if (result) {
                    result.unshift(key);
                    return result;
                }
            } else if (obj[key] === givenValue && key === givenKey) {
                return [key];
            }
        }
    }

    findToken() {

    }


    // removeUniqueId2(token) {
    //     let tokenKeys = Object.keys(token)
    //     tokenKeys.forEach(item => {
    //         if (item !== 'UNIQUE_KEY' && item !== 'spatialAssociation' && item !== '_text') {
    //             if (item !== 'FASC' && item !== 'INAS')
    //                 delete token[item].UNIQUE_KEY
    //
    //             if (Object.keys(token[item]).length > 0) {
    //                 this.removeUniqueId2(token[item])
    //             }
    //         }
    //     })
    // }

    findSubKeyAndDelete(token, attrKey) {
        let tokenKeys = Object.keys(token)
        tokenKeys.forEach(item => {
            if (this.isKeyFound)
                return

            if (item !== 'spatialAssociation' && item !== '_text') {
                // console.log("ii", token[item], attrKey)

                if (token[item].UNIQUE_KEY === attrKey) {
                    // console.log("dd2", ":", token,  ":", token[item],  ":", item,  ":", tokenKeys.length,  ":", tokenKeys)

                    if (tokenKeys[0] === '0') {

                        // alert(tokenKeys.length)
                        // console.log("dd ___ ARRAY", Number(item))
                        token.splice(Number(item), 1)
                        this.isKeyFound = true

                        if (tokenKeys.length === 2) {
                            // alert("aaaa")
                            // delete token[item]
                            // console.log("aaaa", token)
                        }

                    } else {
                        this.isKeyFound = true
                        delete token[item]
                    }
                } else {
                    if (Object.keys(token[item]).length > 0 && item !== 'UNIQUE_KEY') {
                        this.findSubKeyAndDelete(token[item], attrKey)
                        // if (x !== undefined) {
                        //     console.log("tt2", x)
                        //     return x
                        // }
                    }
                }
            }
        })
    }

    findKeyAndDelete(tokens, attrKey) {
        this.isKeyFound = false

        tokens.forEach(token => {

            if (this.isKeyFound)
                return

            if (token.UNIQUE_KEY === attrKey) {
                this.isKeyFound = true
                // console.log("dd1", token)
            } else {
                if (Object.keys(token).length > 0) {
                    this.findSubKeyAndDelete(token, attrKey)
                }
            }
        })
        return null
    }

    deleteData(id, key, component_id, component_key, obj) {
        // console.log("jjjj", id, key, component_id, component_key, obj)
        let attrKey = obj.key
        let features = _.get(this.tokenXML, "Dataset.FeatureObjects.FeatureObject");

        let item = this.findKeyAndDelete(features, attrKey)
        // console.log("tt", this.objByKey)
        // delete this.objByKey

        // features.forEach(token => {
        //     if (token.UNIQUE_KEY === attrKey) {
        //         alert(1)
        //         return
        //     }
        //     if (Object.keys(token).length > 0) {
        //         this.findKey(token, attrKey)
        //     }
        // })


        let token = features.find((item) => item?.ObjectId?.rcid?.UNIQUE_KEY === (component_id === -1 ? id : component_id))
        // let token = features.find((item) => item?.ObjectId?.rcid?._text === (component_id === -1 ? id : component_id))
        // console.log("jjjj", token)
        //
        // console.log("jjjj", this.tokenXML)

        // delete token[obj.code]
    }

    findSubKeyAndEdit(token, attrKey, val, obj) {
        let tokenKeys = Object.keys(token)
        tokenKeys.forEach(item => {
            if (item !== 'spatialAssociation' && item !== '_text') {
                // console.log("ii", token[item], attrKey)

                if (token[item].UNIQUE_KEY === attrKey) {
                    // console.log("tt1", token[item])
                    if (obj.type === "enumeration") {
                        val = _.find(obj.availableValues['S100FC:listedValue'], {"S100FC:label": val})['S100FC:code']
                        val = String(val)
                    }
                    token[item]["_text"] = val
                } else {
                    if (Object.keys(token[item]).length > 0 && item !== 'UNIQUE_KEY') {
                        this.findSubKeyAndEdit(token[item], attrKey, val, obj)
                        // if (x !== undefined) {
                        //     console.log("tt2", x)
                        //     return x
                        // }
                    }
                }
            }
        })
    }

    findKeyAndEdit(tokens, attrKey, val, obj) {
        tokens.forEach(token => {
            if (token.UNIQUE_KEY === attrKey) {
            } else {
                if (Object.keys(token).length > 0) {
                    this.findSubKeyAndEdit(token, attrKey, val, obj)
                }
            }
        })
        return null
    }

    editData(id, key, component_id, component_key, obj, val) {
        console.log("ee1224", id, key, component_id, component_key, obj, val)
        let attrKey = obj.key

        let features = _.get(this.tokenXML, "Dataset.FeatureObjects.FeatureObject");

        this.findKeyAndEdit(features, attrKey, val, obj)

        // console.log("jjjj", this.tokenXML)
        // let token = features.find((item) => item?.ObjectId?.rcid?._text === (component_id === -1 ? id : component_id))

        // console.log("jjjj", token)

        // if (obj.type === "enumeration") {
        //     val = _.find(obj.availableValues['S100FC:listedValue'], {"S100FC:label": val})['S100FC:code']
        // }
        // token[obj.code] = {"_text": val}
    }

    editCoordinates(componentId, lat, lon) {

        console.log("ee12", componentId, lat, lon)

        let points = _.get(this.tokenXML, "Dataset.Points.Point");
        points.forEach((item, i) => {
            let id = _.get(item, "_attributes.Id");
            if (id === componentId) {
                if (lat !== '')
                    item['Lat']['_text'] = lat
                else
                    item['Lon']['_text'] = lon
            }

        })

    }

}
