<template>
  <div>
    <b-container v-show="is_processing" fluid>
      <div align="center" class="text-centercenter text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>&nbsp;Processing...</strong>
      </div>
    </b-container>
    <b-container v-show="!is_processing" fluid>
      <b-row>
        <b-col :lg="displayContent === 'showInfo' ? 3 : 4">
          <b-tabs v-model="tabIndex">
            <b-tab title="Source Messages">
              <messages-view></messages-view>
            </b-tab>
            <!--            <b-tab title="Cells' Tokens" v-if="$store.state.fickle.tokens.is_ready">-->
            <b-tab title="Cells' Tokens">
              <!--              <b-button class="mt-2 ml-1" size="sm" variant="info" @click="uploadFile">Upload</b-button>-->
              <!--              <b-button class="mt-2 ml-1" size="sm" variant="info" v-if="$store.state.fickle.tokens.is_ready" @click="saveFile">Save</b-button>-->
              <tokens-tree :parsers="parsers"></tokens-tree>
            </b-tab>
            <b-tab v-if="$store.state.fickle.tokens.log.length > 0" title="Log" :disabled="currentMessage === ''">
              <log-view></log-view>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col :lg="displayContent === 'showInfo' ? 6 : 8">
          <!--          <b-row>-->
          <!--            <b-col>-->
          <b-tabs v-model="mapsTabIndex" content-class="mt-3">
            <b-tab title="S57" active>
              <my-map57 :map=map57 :height=map_size></my-map57>
            </b-tab>
            <b-tab title="S101">
              <my-map101 :map=map101 :height=map_size></my-map101>
            </b-tab>
          </b-tabs>
          <!--            </b-col>-->
          <!--          </b-row>-->
          <!--          <b-row>-->
          <!--            <b-col>-->
          <!--              <b-card v-if="this.showLog">-->
          <!--                Patch applied to cell NOA30000-->
          <!--              </b-card>-->
          <!--            </b-col>-->
          <!--          </b-row>-->
        </b-col>
        <b-col v-if="displayContent === 'showInfo'" lg="3">
          <h6 v-if="this.mapsTabIndex === 0" align="center" class="mt-2">MAP INFO (S57)</h6>
          <h6 v-else align="center" class="mt-2">MAP INFO (S101)</h6>
          <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="closeMapInfo()">
            <b-icon icon="x" font-scale="1.5"></b-icon>
          </b-button>

          <div v-if="!$store.state.fickle.tokens.objectsInfoLoading">
            <div v-for="(item, i) in $store.state.fickle.tokens.objectsInfo.info">
              <div v-if="mapsTabIndex === 0">
                <span class='listsmall'><a href='#' @mouseover='showObject(item.id, item.name, item.locationType, i+1, false)' @click='showObject(item.id, item.name, item.locationType, i+1, true)'>{{ i + 1 }}: {{
                    item.name
                  }} </a><br></span>
              </div>
              <div v-if="mapsTabIndex === 1 && item.name !== 'DataCoverage'">
                <span class='listsmall'><a href='#' @mouseover='showObject(item.id, item.name, item.locationType, i+1, false)' @click='showObject(item.id, item.name, item.locationType, i+1, true)'>{{ i + 1 }}: {{
                    item.name
                  }} </a><br></span>
              </div>

            </div>
            <hr>
            <div v-html="map_info_text"/>
          </div>
          <div class="text-center text-danger my-2" v-else>
            <b-spinner small class="align-middle"></b-spinner>
            <strong>&nbsp;Loading...</strong>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {TokenParser} from "@/views/tokens/parser";
import TokensMap57 from "@/views/tokens/TokensMap57.vue";
import TokensMap101 from "@/views/tokens/TokensMap101.vue";
import TokensView from "@/views/tokens/TokensView.vue"
import TokensMessages from "@/views/tokens/TokensMessages.vue"
import TokensLogs from "@/views/tokens/TokensLog.vue"
import {eventBus, tokens_map57, tokens_map101} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {file} from "paths.macro";
import router from "@/router";
import {bbbb} from "@/my-leaf/tokens/token-leaf57";
import * as xml_js from "xml-js";
import _ from "lodash";

export default {
  components: {MyMap57: TokensMap57, MyMap101: TokensMap101, TokensTree: TokensView, LogView: TokensLogs, MessagesView: TokensMessages},
  data() {
    return {
      is_processing: false,
      messages: null,
      parsers: [],
      tabIndex: 0,
      mapsTabIndex: 0,
      showCommand: false,
      showLog: false,
      currentMessage: '',
      messageType: '',
      selectedInfo: '',
      proposedInfo: '',
      plannedInfo: '',
      resultInfo: '',
      windowWidth: window.innerWidth,
      map57: tokens_map57,
      map101: tokens_map101,
      displayContent: '',
      map_info_text: '',
      map_info_object: '',
      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,
    }
  },
  watch: {
    'tabIndex'(newVal, oldVal) {
      if (newVal === 0) {
        this.currentMessage = ''
        this.messageType = ''
        this.showCommand = false
        this.showLog = false

        // this.$refs.message_table.refresh()
        eventBus.$emit('refresh-messages')

        tokens_map57.bbbb4()
      }
      // if (newVal === 1 && !this.$store.state.fickle.tokens.is_ready) {
      //
      //   let tp = new TokenParser()
      //   console.log("fc1")
      //   tp.loadFC()
      //   console.log("fc2")
      //
      //   let cellName = 'GB50182A'
      //   tp = new TokenParser()
      //   tp.init(cellName, 'tokens1.xml')
      //   this.parsers.push({cell: cellName, parser: tp})
      //   console.log("fc3")
      //
      //   cellName = 'GB50182B'
      //   tp = new TokenParser()
      //   tp.init(cellName, 'tokens2.xml')
      //   this.parsers.push({cell: cellName, parser: tp})
      //   console.log("fc4")
      //
      //   store.commit("fickle/setTokensReady")
      // }
    },
    'mapsTabIndex'(newVal) {
      this.closeMapInfo()

      if (newVal === 1) {
        eventBus.$emit('invalidate-s101')
      }
    },

    'showLog'(newVal) {
      if (newVal) {

      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    if (!this.messages) {
      fetch('./sourcemessages.json')
          .then((response) => response.json())
          .then((json) => {
            console.log(json)
            this.messages = json

            this.messages.forEach(item => {
              item.changed = false
            })

          });
    }

    // this.messages[0].changed = false
    // this.messages[1].changed = true
    // this.messages[2].changed = false


    eventBus.$on('show-tokens-map-info', (payload) => {
      this.map_info_text = ""
      this.map_info_object = ""
      store.commit("fickle/setTokensObjectInfo", payload)
      this.displayContent = 'showInfo'
    })

    this.resetTokensOnServer("orig_101EE0050823C__.xml", "EE50823C")

    setTimeout(() => {
      this.resetTokensOnServer("orig_101EE004D1006__.xml", "EE4D1006")
    }, 2000)

    setTimeout(() => {
      this.resetTokensOnServer("orig_101EE004D1207__.xml", "EE4D1207")
    }, 4000)

    // this.tp = new TokenParser()
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
    autoEdit(cellName) {
      let index = _.findIndex(this.parsers, {cell: cellName})
      this.parsers[index].parser.autoEdit(this.messageType)
      this.parsers[index].parser.restoreTree(cellName)
      this.parsers[index].parser.makeDescriptor()

    },
    async selectMessage(message, type, selected, proposed, planned, result) {
      this.currentMessage = message
      this.messageType = type

      this.selectedInfo = selected
      this.proposedInfo = proposed
      this.plannedInfo = planned
      this.resultInfo = result

      this.parsers = []
      store.commit("fickle/clearTokens")

      if (!this.$store.state.fickle.tokens.is_ready) {
        let tp = new TokenParser()
        await tp.loadFC()
      }


      if (type === "1") {
        let cellName = 'EE50823C'
        let tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE0050823C__.xml')

        cellName = 'EE4D1207'
        tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE004D1207__.xml')

        cellName = 'EE4D1006'
        tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE004D1006__.xml')

        store.commit("fickle/setTokensReady")

      } else if (type === "2") {
        let cellName = 'EE50823C'
        let tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE0050823C__.xml')

        cellName = 'EE4D1006'
        tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE004D1006__.xml')

        store.commit("fickle/setTokensReady")
      } else if (type === "3") {
        let cellName = 'EE4D1207'
        let tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE004D1207__.xml')

        store.commit("fickle/setTokensReady")
      } else if (type === "4") {
        let cellName = 'EE4D1207'
        let tp = new TokenParser()
        this.parsers.push({cell: cellName, parser: tp})
        await tp.init(cellName, '101EE004D1207__.xml')

        store.commit("fickle/setTokensReady")
      }

      setTimeout(() => {
        this.tabIndex = 1
        eventBus.$emit('prepare-tokens-details')
      }, 500);

    },
    showObject(id, name, type, i, needZoom) {
      this.map_info_text = "<span class='small-cyan'>" + i + ": " + name + " (" + type + ")</span><hr>"
      this.map_info_object = id
      this.$store.state.fickle.tokens.objectsInfo.info.forEach((item, index) => {
        if (item.name === name && i - 1 === index) {
          this.map_info_text += ""
          item.attributes.forEach((attribute, i) => {
            this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
          })
          this.map_info_text += "<hr>"
        }
      })

      let points = this.$store.state.fickle.tokens.objectsInfo.info.filter(function (e) {
        return e["id"] === id;
      });

      let lines = points[0].lines

      if (this.mapsTabIndex === 0)
        eventBus.$emit("draw-tokens-object-s57", {lines: lines, type: type, zoom: needZoom})
      else
        eventBus.$emit("draw-tokens-object-s101", {lines: lines, type: type, zoom: needZoom})
    },
    closeMapInfo() {
      this.displayContent = ''
      if (this.mapsTabIndex === 0)
        eventBus.$emit('clear-tokens-object-s57')
      else
        eventBus.$emit('clear-tokens-object-s101')
    },

    resetTokensOnServer(tokensFileName, cellName) {
      let URL = "https://ntiles.bgeo.fi:6027/update_token_map?cellName=" + cellName

      fetch(tokensFileName)
          .then(response => response.blob())
          .then(blob => {
            let reader = new FileReader();
            reader.readAsText(blob, 'UTF-8');

            let xml;
            reader.onload = readerEvent => {
              xml = readerEvent.target.result;

              myAxios.post(URL, xml, {headers: {"content-type": "application/xml",}})
                  .then((resp) => {
                    console.log(resp)
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            }
          })
    },

    sendFile(cellName, xml) {
      this.is_processing = true
      let URL = "https://ntiles.bgeo.fi:6027/update_token_map?cellName=" + cellName

      myAxios
          .post(URL, xml, {
            headers: {
              "content-type": "application/xml",
            }
          })
          .then((resp) => {
            console.log(resp)
            // alert(1)
            this.is_processing = false
            tokens_map57.bbbb2()
            // tokens_map101.bbbb2()
            tokens_map101.bbbb8()
          })
          .catch((error) => {
            alert("Try later...")
            console.log(error)
          })
    },

    saveFile(cellName) {
      let index = _.findIndex(this.parsers, {cell: cellName})
      let xml = this.parsers[index].parser.saveXML()

      this.sendFile(cellName, xml)

      if (this.messageType === "2")
        this.messages[0].changed = true

      if (this.messageType === "3")
        this.messages[1].changed = true

      if (this.messageType === "4")
        this.messages[2].changed = true


      // this.messages[0].changed = true
      // this.messages[1].changed = true
      // this.messages[2].changed = true


      // dddd()
      // tokensMessages

      // let a = document.createElement("a");
      // let file = new Blob([xml], {type: 'text/plain'});
      // a.href = URL.createObjectURL(file);
      // a.download = cellName + '_edited' + ".xml";
      // a.click();
    },
  }
}
</script>
