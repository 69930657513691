<template>
  <div>
    <b-modal
        id="zone-name" v-model="showModal" title="Zone name" @show="resetModal" @hidden="resetModal" no-close-on-backdrop no-close-on-esc hide-header-close ok-only @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label-for="name-input"
            :invalid-feedback="feedback"
            :state="nameState"
        >
          <b-form-input
              id="name-input"
              v-model="name"
              :state="nameState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>


    <!--    <div style="height: 500px; overflow-y: scroll;">-->

    <!--    <details>-->
    <!--      <summary>-->
    <!--        Settings-->
    <!--      </summary>-->
    <!--      <b-card v-if="map_info_text === ''" class="mb-2 mr-2" border-variant="secondary" header="Settings" header-border-variant="secondary">-->
    <!--        <b-card-text>-->

<!--    <b-button @click="scroolToSelected()">Scroll</b-button>-->

    <b-tabs pills card small v-model="tabIndex">
      <b-tab title="My zones" v-if="$store.state.routes.nogoZones.zones.length > 0">
        <div v-for="(item) in $store.state.routes.nogoZones.zones" class="mt-2">
          <b-container>
            <b-row>
              <b-row>
                &nbsp;&nbsp;&nbsp;&nbsp;<b-button class="border-0 float-right" size="sm" variant="outline-danger"
                                                  @click="removeZone(item.name)">
                <b-icon icon="x" font-color="red" font-scale="1.0"></b-icon>
              </b-button>
                <b-form-checkbox v-model="zonesSettings" v-bind:value="item.name" name="check-button-1" switch>
                  <a href="#" @click='showZone(item.name)'>{{ item.name }}</a>
                </b-form-checkbox>
              </b-row>
            </b-row>
          </b-container>
        </div>

      </b-tab>
      <b-tab title="ENC zones">
        <div class="mt-2 ml-2">
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'TSEZNE'" name="check-button-1" switch>
            Traffic separation zone
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'ISTZNE'" name="check-button-1" switch>
            Inshore traffic zone
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-OTHER'" name="check-button-1" switch>
            Restricted area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'CTNARE'" name="check-button-1" switch>
            Caution area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'OSPARE'" name="check-button-1" switch>
            Offshore production area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-14'" name="check-button-1" switch>
            Areas to be avoided
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'MIPARE'" name="check-button-1" switch>
            Military practice area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'SPLARE'" name="check-button-1" switch>
            Seaplane landing area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'SUBTLN'" name="check-button-1" switch>
            Submarine transit lane
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'ACHARE'" name="check-button-1" switch>
            Anchorage area
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'MARCUL'" name="check-button-1" switch>
            Marine farm/aquaculture
          </b-form-checkbox>
          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-28'" name="check-button-1" switch>
            PSSA
          </b-form-checkbox>
        </div>
      </b-tab>

      <b-tab title="Route's zones" v-if="$store.state.routes.route.length > 0">
        <div style="height: 500px; overflow-y: scroll">
          <div v-for="(item, j) in $store.state.routes.dangers_filtered">
            <b-card class="mb-2 mt-2" v-if="item.id === selectedObjectId" border-variant="primary" header-bg-variant="primary" header-text-variant="white" :id="item.id">
              <b-card-header class="pl-2 pt-1 pb-1" header-bg-variant="primary" header-border-variant="primary" header-text-variant="white">
                <b-form-checkbox v-model="dangersSettings" v-bind:value="item.id" name="check-button-2" switch>
                  <a style="color: white" href="#" @click='hideObject(item.id)'>{{ item.name }}</a>
                </b-form-checkbox>
              </b-card-header>
              <div class="mt-2" v-html="prepareInfo1(item.id)"/>
            </b-card>
            <b-card class="mb-2 mt-2" v-if="isRestrictedArea(item.name) && item.id !== selectedObjectId" :id="item.id">
              <b-card-header class="pl-2 pl-2 pt-1 pb-1">
                <b-form-checkbox v-model="dangersSettings" v-bind:value="item.id" name="check-button-2" switch>
                  <a href="#" @click='showObject(item.id)'>{{ item.name }} </a>
                </b-form-checkbox>
              </b-card-header>
              <div class="mt-2" v-html="prepareInfo1(item.id)"/>
            </b-card>
          </div>
        </div>
      </b-tab>
    </b-tabs>

<!--    <div class="accordion">-->
<!--      <details v-if="$store.state.routes.nogoZones.zones.length > 0" class="card">-->
<!--        <summary class="card-header">My zones</summary>-->
<!--        <div v-for="(item) in $store.state.routes.nogoZones.zones" class="mt-2">-->
<!--          <b-container>-->
<!--            <b-row>-->
<!--              <b-row>-->
<!--                &nbsp;&nbsp;&nbsp;&nbsp;<b-button class="border-0 float-right" size="sm" variant="outline-danger"-->
<!--                                                  @click="removeZone(item.name)">-->
<!--                <b-icon icon="x" font-color="red" font-scale="1.0"></b-icon>-->
<!--              </b-button>-->
<!--                <b-form-checkbox v-model="zonesSettings" v-bind:value="item.name" name="check-button-1" switch>-->
<!--                  <a href="#" @click='showZone(item.name)'>{{ item.name }}</a>-->
<!--                </b-form-checkbox>-->
<!--              </b-row>-->
<!--            </b-row>-->
<!--          </b-container>-->
<!--        </div>-->
<!--      </details>-->

<!--      <details class="card">-->
<!--        <summary class="card-header">Restricted zones</summary>-->
<!--        <div class="mt-2 ml-2">-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'TSEZNE'" name="check-button-1" switch>-->
<!--            Traffic separation zone-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'ISTZNE'" name="check-button-1" switch>-->
<!--            Inshore traffic zone-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-OTHER'" name="check-button-1" switch>-->
<!--            Restricted area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'CTNARE'" name="check-button-1" switch>-->
<!--            Caution area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'OSPARE'" name="check-button-1" switch>-->
<!--            Offshore production area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-14'" name="check-button-1" switch>-->
<!--            Areas to be avoided-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'MIPARE'" name="check-button-1" switch>-->
<!--            Military practice area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'SPLARE'" name="check-button-1" switch>-->
<!--            Seaplane landing area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'SUBTLN'" name="check-button-1" switch>-->
<!--            Submarine transit lane-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'ACHARE'" name="check-button-1" switch>-->
<!--            Anchorage area-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'MARCUL'" name="check-button-1" switch>-->
<!--            Marine farm/aquaculture-->
<!--          </b-form-checkbox>-->
<!--          <b-form-checkbox v-model="categoriesSettings" v-bind:value="'RESARE-CATREA-28'" name="check-button-1" switch>-->
<!--            PSSA-->
<!--          </b-form-checkbox>-->
<!--        </div>-->

        <!--            <div v-for="(category) in $store.state.routes.dangers_keys.categories">-->
        <!--              <b-form-checkbox v-model="categoriesSettings" v-bind:value="category" name="check-button-1" switch>-->
        <!--                {{ category }}-->
        <!--              </b-form-checkbox>-->
        <!--            </div>-->
<!--      </details>-->
      <!--        </b-card-text>-->
      <!--      </b-card>-->
      <!--    </details>-->

      <!--    <b-card class="mb-2" v-if="map_info_text !== ''" bg-variant="light">-->
      <!--      <b-button class="border-0 float-right" size="sm" variant="outline-secondary" @click="cleanInfo()">-->
      <!--        <b-icon icon="arrow-left-short" font-scale="1.5"></b-icon>-->
      <!--      </b-button>-->
      <!--      <div v-html="map_info_text"/>-->
      <!--    </b-card>-->

<!--      <div v-if="$store.state.routes.route.length > 0">-->
<!--        <details open class="card">-->
<!--          <summary class="card-header">-->
<!--            Route's zones-->
<!--          </summary>-->

<!--          <div style="height: 500px; overflow-y: scroll">-->
<!--            <div v-for="(item, j) in $store.state.routes.dangers_filtered">-->
<!--              <b-card class="mb-2" v-if="item.id === selectedObjectId" bg-variant="light">-->
<!--                <b-card-header>-->
<!--                  <b-form-checkbox v-model="dangersSettings" v-bind:value="item.id" name="check-button-2" switch>-->
<!--                    <a href="#" @click='hideObject(item.id)'>{{ item.name }}</a>-->
<!--                  </b-form-checkbox>-->
<!--                </b-card-header>-->
<!--                <div v-html="prepareInfo1(item.id)"/>-->
<!--              </b-card>-->
<!--              <b-card class="mb-2" v-if="isRestrictedArea(item.name) && item.id !== selectedObjectId">-->
<!--                <b-card-header>-->
<!--                  <b-form-checkbox v-model="dangersSettings" v-bind:value="item.id" name="check-button-2" switch>-->
<!--                    <a href="#" @click='showObject(item.id)'>{{ item.name }}</a>-->
<!--                  </b-form-checkbox>-->
<!--                </b-card-header>-->
<!--                <div v-html="prepareInfo1(item.id)"/>-->
<!--              </b-card>-->

<!--            </div>-->
<!--          </div>-->
<!--        </details>-->
<!--      </div>-->
    </div>

    <!--      <b-table v-model="aaa" v-if="$store.state.routes.route.length > 0" selectable sticky-header select-mode="single" ref="wptable2" id="routes-table2" style="max-height: 400px;"-->
    <!--               @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items" :fields="visible_fields">-->
    <!--        <template #cell(name)="data">-->
    <!--          <span v-if="isDanger(data.index)" class="smt14">WP{{ data.index }}</span>-->
    <!--          <span v-else class="smt4">WP{{ data.index }}</span>-->
    <!--        </template>-->

    <!--        <template #row-details="row">-->
    <!--                <div v-for="(item, j) in $store.state.routes.dangers">-->
    <!--                  <div v-if="item.legIndex === row.index && isRestrictedArea(item.name)">-->
    <!--                    <b-form-checkbox v-model="dangersSettings" v-bind:value="item.id" name="check-button-2" switch>-->
    <!--                      <div v-if="item.id === selectedObjectId">-->
    <!--                        {{ item.name }}-->
    <!--                      </div>-->
    <!--                      <div v-else>-->
    <!--                        <a href="#" @click='showObject(item.id)'>{{ item.name }}</a>-->
    <!--                      </div>-->
    <!--                    </b-form-checkbox>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--        </template>-->

    <!--        <template #cell(count)="data">-->
    <!--          {{ getCount(data.index) }}-->
    <!--        </template>-->

    <!--      </b-table>-->

  </div>


</template>

<script>

import {eventBus} from "@/main";
import store from "@/store";
import {commit} from "lodash/seq";
import {updateRoute} from "@/helpers/lsHelpers";
import {myAxios} from "@/services/myAxios";
import L from "leaflet";
import Details from "@/views/ecdis/Details.vue";


export default {
  name: "ZonesSettings",
  components: {Details},
  data() {
    return {
      tabIndex: 0,
      selectedObjectId: '',
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      map_info_text: '',
      details: null,
      name: '',
      nameState: null,
      feedback: "Name is required",
      submittedNames: [],
      showModal: false,
      zones: [],
      zonesSettings: [],
      categoriesSettings: [],
      dangersSettings: [],
      addedDangers: ['aaa']
    }
  },
  mounted() {

    setTimeout(() => {
      this.details = document.querySelectorAll("details")

      this.details.forEach((detail) => {
        detail.addEventListener("toggle", () => {
          if (detail.open) this.setTargetDetail(detail);
        });
      });

    }, 1000)

    eventBus.$off('enter-zone-name')
    eventBus.$on('enter-zone-name', (payload) => {
      this.zones = payload.zones
      if (this.zones.length > 0) {
        this.showModal = true

      }
    })

    eventBus.$off('clear-current-wp-index-zones')
    eventBus.$on('clear-current-wp-index-zones', () => {
      this.currentRowIndex = -1
    })

    eventBus.$off('move-current-zones')
    eventBus.$on('move-current-zones', () => {
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$off('move-next-zones')
    eventBus.$on('move-next-zones', () => {
      console.log("qqq", this.currentRowIndex)

      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      // this.map.closePopup();

      // eventBus.$emit('switch-popup', this.currentRowIndex)
      // eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.centerRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })

    eventBus.$off('move-prev-zones')
    eventBus.$on('move-prev-zones', () => {
      // console.log("aaa", this.currentRowIndex, this.focusWPIndex)
      // console.log(this.currentRowIndex)
      // console.log(this.prevRowIndex)
      // console.log(this.focusWPIndex)
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
        console.log("eee1")
      } else {
        this.selectRow(this.focusWPIndex)
        console.log("eee2", this.focusWPIndex)
      }

      // this.map.closePopup();

      // eventBus.$emit('switch-popup', this.currentRowIndex)
      // eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.centerRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })

    eventBus.$off('select-object')
    eventBus.$on('select-object', (id) => {
      // alert(id)
      this.selectedObjectId = id
      this.showObject(id)

      let targetElm = document.getElementById(id);
      targetElm.scrollIntoView()
    })

    eventBus.$off('hide-object')
    eventBus.$on('hide-object', (id) => {
      // alert(id)
      this.selectedObjectId = ''
      this.hideObject(id)

      // let targetElm = document.getElementById(id);
      // targetElm.scrollIntoView()
    })

    eventBus.$off('select-routetable-row-zones')
    eventBus.$on('select-routetable-row-zones', (id) => {
      console.log("eee", id)
      if (this.getCount(id) > 0)
        store.commit("routes/setRoutePointsDetailsFlag", id)
      else
        store.commit("routes/clearRoutePointsDetailsFlag", id)

      this.currentRowIndex = id
      this.$refs.wptable2.selectRow(id);
      const tbody = this.$refs.wptable2.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      // row.scrollIntoView()
      row.scrollIntoView({block: 'center'})
      // eventBus.$emit('show-wp-index', id)
    })

    eventBus.$off('set-focus-index-zones')
    eventBus.$on('set-focus-index-zones', (index) => {
      this.focusWPIndex = index
    })

  },
  watch: {
    '$store.state.routes.nogoZones.zones'() {
      setTimeout( () => {
        this.tabIndex = 0
      }, 500)

    },
    '$store.state.routes.route'() {
      setTimeout( () => {
        this.tabIndex = 2
      }, 500)

    },
    '$store.state.routes.dangers_keys.categories'() {

      setTimeout(() => {
        this.details = document.querySelectorAll("details")

        this.details.forEach((detail) => {
          detail.addEventListener("toggle", () => {
            if (detail.open) this.setTargetDetail(detail);
          });
        });

      }, 1000)

    },
    'zonesSettings'() {
      this.saveData()
      eventBus.$emit("redraw-zones")
    },
    'dangersSettings'() {
      this.saveData()
    },
    'categoriesSettings'() {
      this.saveData()
    },
  },
  computed: {
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'count', label: 'Zones count', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    // scroolToSelected() {
    //   let targetElm = document.getElementById('3866475590_1035');
    //   targetElm.scrollIntoView()
    // },
    items() {
      return this.$store.state.routes.route
    },
    cleanInfo() {
      this.map_info_text = ''
      this.selectedObjectId = ''
      eventBus.$emit('clear-object')
    },
    getDangers(item) {

      let val = "iii"
      // console.log(item.id, this.addedDangers.includes(item.id))

      // this.addedDangers.forEach(id => {
      //   console.log("oooo", id, item.id)
      //   if (id === item.id) {
      //     val = "aaa"
      //   } else {
      //     this.addedDangers.push(item.id)
      //     val = "bbb"
      //   }
      // })

      if (!this.addedDangers.includes(item.id)) {
        val = "bbb"
        this.addedDangers.push(item.id)
      }

      // console.log("eee", this.addedDangers)

      console.log("oooo", val)

      return val
      // this.addedDangers.push(item.id)
      // return item.name + " " + item.id
      // } else {
      // return "aaa"
      // }

    },
    isDanger(index) {
      let dangers = this.$store.state.routes.dangers.filter(function (e) {
        return e.isDanger === true && e.legIndex === index
      });
      return dangers.length > 0
    },
    selectRow(index) {
      eventBus.$emit('clear-object')
      this.map_info_text = ''

      if (this.getCount(index) > 0)
        store.commit("routes/setRoutePointsDetailsFlag", index)
      else
        store.commit("routes/clearRoutePointsDetailsFlag", index)

      // alert(index)
      // alert('aaa')
      this.$refs.wptable2.unselectRow(index);
      setTimeout(() => {
        this.$refs.wptable2.selectRow(index);
      }, 100)
//      this.$refs.wptable.selectRow(index);
      // this.scrollToRow(index);
      this.currentRowIndex = index

      // eventBus.$emit('show-wp-index', index)
      eventBus.$emit('click-wp', index)

      // this.rowsToSelect[index].click()
      // console.log(index, this.rowsToSelect[index], this.currentRowIndex, 'wpin')
    },
    onRowClicked(row, index) {
      console.log(index)
      eventBus.$emit('clear-object')
      this.map_info_text = ''

      if (this.getCount(index) > 0)
        store.commit("routes/setRoutePointsDetailsFlag", index)
      else
        store.commit("routes/clearRoutePointsDetailsFlag", index)
      this.currentIndex = index
    },
    onRowSelected(items) {
      eventBus.$emit('clear-object')
      this.map_info_text = ''

      eventBus.$emit('wp-selected', items[0])
    },
    countLegObjects(legIndex) {
      let objects = this.$store.state.routes.dangers.filter(function (e) {
        return e.legIndex === legIndex + 1;
      });

      return objects.length
    },
    // getCount(index) {
    //   let items = this.$store.state.routes.dangers.filter(function (e) {
    //     return e.legIndex === index;
    //   });
    //
    //   return items.length
    // },
    getCount(index) {
      let count = 0;
      // let index = data.index
      // let name = data.name
      let items = this.$store.state.routes.dangers.filter(function (e) {
        return e.legIndex === index
        // return this.isRestrictedArea(e.name);
      });

      count = 0
      if (items.length > 0) {
        console.log("ggg", items)
        items.forEach(item => {
          if (this.isRestrictedArea(item.name))
            count++
        })
        // let filtered = items.filter(function (e) {
        //   return this.isRestrictedArea(e.name);
        // });

        // console.log("ggg1", filtered)
      }

      return count
    },
    setTargetDetail(targetDetail) {
      this.details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.open = false;
        }
      });
    },
    saveData() {
      // console.log('ggg', this.zonesSettings)
      // console.log('ggg', this.dangersSettings)
      store.commit("fickle/setNoGoZones", {zones: this.zonesSettings, objects: this.dangersSettings, categories: this.categoriesSettings})
    },
    getDangersList() {
      let list = "<details><summary>"
      this.$store.state.routes.dangers_keys.forEach(category => {
        list += category.name + "</summary>"

      })


      // <div v-for="(category) in this.$store.state.routes.dangers_keys">
      //   <b-container>
      //     <b-row>
      //       <details>
      //         <summary>{{ category }}</summary>
      //         <div v-for="(item) in this.$store.state.routes.dangers">
      //           <!--              <div v-if="item.name === category">-->
      //           {{ item.name }}
      //           <!--              </div>-->
      //         </div>
      //       </details>
      //       <!--          <b-form-checkbox v-model="dangersSettings" v-bind:value="item" name="check-button-2" switch>-->
      //       <!--            <a href="#" @click='showZone(item)'>{{item}}</a>-->
      //       <!--          </b-form-checkbox>-->
      //     </b-row>
      //   </b-container>
      // </div>
    },
    removeZone(name) {
      // console.log("gggg", name)
      // console.log("gggg", this.zones)
      store.commit("routes/deleteZone", name)
      eventBus.$emit('clear-zone')
      updateRoute('route_temp_3', this.$store.state.routes.nogoZones)
    },
    showZone(name) {
      eventBus.$emit("draw-zone", name)
    },
    isRestrictedArea(name) {
      let val = false
      if (name === "Traffic separation zone" ||
          name === "Inshore traffic zone" ||
          name === "Caution area" ||
          name === "Offshore production area" ||
          name === "Military practice area" ||
          name === "Sea-plane landing area" ||
          name === "Submarine transit lane" ||
          name === "Anchorage area" ||
          name === "Marine farm/culture" ||
          name.startsWith("Restricted area")
      )
        val = true
      return val
    },
    getMetricInfo(id) {
      let objects = store.state.fickle.routes.metric.filter(function (e) {
        return e.id === id
      })

      let info = []
      if (objects.length > 0)
        info = objects[0].attributes
      return info
    },
    prepareInfo(id) {
      this.map_info_text = ''

      let objects = store.state.fickle.routes.metric.filter(function (e) {
        return e.id === id
      })

      let info = []
      if (objects.length > 0)
        info = objects[0].attributes

      info.forEach(attribute => {
        this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
      })

      // this.$store.state.fickle.routes.objectsInfo.info.forEach((item, index) => {
      //   if (item.name === name && i-1 === index) {
      //
      //     this.map_info_text += ""
      //     item.attributes.forEach((attribute, i) => {
      //       this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
      //     })
      //     this.map_info_text += "<hr>"
      //   }
      // })
    },
    prepareInfo1(id) {
      let map_info_text = ''

      let objects = store.state.fickle.routes.metric.filter(function (e) {
        return e.id === id
      })

      let info = []
      if (objects.length > 0)
        info = objects[0].attributes

      let objname = ''
      let category = ''
      let information = ''
      let scale = ''

      info.forEach(attribute => {

        if (attribute.name.startsWith("Object name"))
          objname += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
        else if (attribute.name.startsWith("Category"))
          category += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
        else if (attribute.name.startsWith("Information"))
          information += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
        else if (attribute.name.startsWith("Scale"))
          scale += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
        else
          map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
      })
      map_info_text = objname + category + information + map_info_text + scale

      return map_info_text
    },
    hideObject(id) {
      eventBus.$emit('clear-selected-object')
      this.selectedObjectId = ''
    },
    // showAllObjects() {
    //   if (store.state.fickle.routes.metric.length > 0) {
    //     eventBus.$emit("draw-all-nogo-objects")
    //   }
    // },
    showObject(id) {
      this.selectedObjectId = id
      let objects = store.state.fickle.routes.metric.filter(function (e) {
        return e.id === id
      })
      if (objects.length > 0) {
        eventBus.$emit("draw-nogo-object", objects[0])
        // this.prepareInfo(id)
      } else {
        // myAxios.get(`https://mwend.bgeo.fi:6004/api/v1/objectinfo?id=${id}`)
        //     .then(resp => {
        //       store.commit("fickle/setMetric", resp.data)
        //       eventBus.$emit("draw-nogo-object", resp.data)
        //       // this.prepareInfo(id)
        //     });
      }
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity()

      if (valid) {
        let formName = this.name
        let found = this.$store.state.routes.nogoZones.zones.filter(function (e) {
          return e.name === formName
        })
        if (found.length > 0) {
          this.feedback = "This name was already used"
          valid = false
        }
      } else {
        this.feedback = "Name is required"
      }

      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('zone-name')
      })
      store.commit("routes/setZone", {name: this.name, zones: this.zones})
      // updateRoute('route_temp_3', {name: this.name, zones: this.zones})
      updateRoute('route_temp_3', this.$store.state.routes.nogoZones)
      eventBus.$emit('clear-nogo-draw')
      eventBus.$emit('change-tab-zones')
    }
  }

}
</script>

<style scoped>
/* Убираем стандартный маркер Chrome */
details summary::-webkit-details-marker {
  display: none
}

/* Убираем стандартный маркер Firefox */
details > summary {
  list-style: none;
}

</style>