<template>
  <div>
    <b-table ref="message_table" selectable select-mode="single" thead-class="d-none" @row-selected="onRowSelected" :items="visible_rows()" :fields="visible_fields">
      <template #cell(changed)="row">
        <span class="small-gray-card">No. {{ row.item.id }}, Date: {{ row.item.date }}<b-icon v-if="row.item.changed" icon="check-all"></b-icon></span>

        <br>
        {{ row.item.message }}
      </template>
    </b-table>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "TokenMessages",
  data() {
    return {
      // messages: null,
    }
  },
  computed: {
    visible_fields() {
      return [
        {key: 'id', label: 'ID', sortable: false, sortDirection: 'desc', thClass: 'd-none', tdClass: 'd-none'},
        {key: 'date', label: 'Date', sortable: false, sortDirection: 'desc', thClass: 'd-none', tdClass: 'd-none'},
        {key: 'title', label: 'Title', sortable: false, sortDirection: 'desc', thClass: 'd-none', tdClass: 'd-none'},
        {key: 'type', label: 'Type', sortable: false, sortDirection: 'desc', thClass: 'd-none', tdClass: 'd-none'},
        {key: 'message', label: 'Message', sortable: false, sortDirection: 'desc', tdClass: 'd-none', thClass: 'd-none'},
        {key: 'changed', label: 'Changed', sortable: false, sortDirection: 'desc', tdClass: 'rowClass', thClass: 'small-black'},
      ]
    }
  },
  mounted() {
    // if (!this.messages) {
    //   this.messages = require("@/data/sourcemessages.json")
    //   this.messages.forEach(item => {
    //     item.changed = false
    //   })
    // }

    eventBus.$off("refresh-messages")
    eventBus.$on("refresh-messages", () => {
      this.$refs.message_table.refresh()
    })

  },
  methods: {
    rowClass(item, type) {
      // if (!item || type !== 'row') return
      console.log("yyyy", item)
      if (item)
        return 'small-green-bkg'
    },
    onRowSelected(items) {
      // if (!items[0].changed)
        this.$parent.$parent.$parent.selectMessage(items[0].message, items[0].type, items[0].selected, items[0].proposed, items[0].planned, items[0].result)
      // this.$refs.message_table.clearSelected()
      // this.$refs.message_table.refresh()
    },
    visible_rows() {
      return this.$parent.$parent.$parent.messages
      // return this.messages
    }
  }
}
</script>

<style scoped>

</style>